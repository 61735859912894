export const inputLength = {
  /*
   * Max lengths below
   */

  // Itineraries
  maxItineraryLabel: 55,
  maxItineraryName: 55,
  maxItineraryDetails: 76,

  // Point of interests
  maxPointOfInterestLabel: 55,
  maxPointOfInterestName: 55,
  maxPointOfInterestDetails: 76,

  // Modules
  maxModuleLabel: 55,
  maxModuleName: 55,
  maxQuizSynthesis: 1000,

  // Questions
  maxQuestionInput: 300,

  // Answers in quiz modules
  maxAnswerInput: 150,
  maxAnswerIncrementalInput: 50,
  maxAnswerExplanation: 500,

  //Participatory quiz
  maxCategoryInput: 150,

  // Medias
  maxMediaLabel: 100,

  /*
   * Min lengths below
   */

  // Projects
  minProjectName: 3,

  // Users
  minUsername: 1,

  // Passwords
  minPassword: 8,

  // Modules Custom
  minModuleCustomKey: 1,
  minModuleCustomName: 1,
};
