import { moduleType } from "app/Enumerations/module-type.enum";
import { Media } from "./media";
import { Project } from "./project";

export class Module {
  id!: number;
  pointOfInterestId!: number | null;
  pointOfInterestName?: string;
  itineraryId?: number;
  itineraryName?: string;
  identificationName!: string;
  state!: number;
  order!: number;
  type!: moduleType;
  readyForPublication!: boolean;
  mediaCount?: number;
  createdAt?: Date;
  publicationDate?: Date;
  lastModificationDate?: Date;
  mediaSynthesis?: Media;
  mediaSynthesisId?: number;
  moduleTranslations: ModuleTranslation[] = [];
  backgroundColor?: string;
  timer?: number;
  growingIcon?: boolean;
  // ModuleQuiz - move to another class later
  questions: Question[] = [];
  // ModuleGallery - move to another class later
  mediaAssociations: MediaAssociation[] = [];
  // ModuleCustom - move to another class later
  moduleCustomTemplateId?: number;
  languagesAssociated: number[] = [];
}

// export class ModuleGallery extends Module {
//   mediaAssociations: MediaAssociation[] = [];
// }

export class ModuleTranslation {
  translatedEntityId!: number;
  languageId!: number;
  name!: string;
  synthesis!: string;

  constructor(moduleId: number, languageId: number, name: string, synthesis: string) {
    this.translatedEntityId = moduleId;
    this.languageId = languageId;
    this.name = name;
    this.synthesis = synthesis;
  }
}

export class MediaAssociation {
  id?: number;
  media!: Media;
  is360!: boolean;
  order!: number;
  isMediaNameTranslated!: boolean;
  mediaAssociationTranslations: MediaAssociationTranslation[] = [];

  constructor(newId: number, media: Media, is360: boolean, order: number, isMediaNameTranslated: boolean, mediaAssociationTranslations: MediaAssociationTranslation[]) {
    this.id = newId;
    this.media = media;
    this.is360 = is360;
    this.order = order;
    this.isMediaNameTranslated = isMediaNameTranslated;
    this.mediaAssociationTranslations = mediaAssociationTranslations;
  }
}

export class MediaAssociationTranslation {
  translatedEntityId!: number;
  languageId!: number;
  name!: string;

  constructor(translatedEntityId: number, languageId: number, name: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.name = name;
  }
}

export class Question {
  id?: number;
  questionTranslations: QuestionTranslation[] = [];
  media?: Media;
  mediaId?: number;
  // For the true/false module, the possibilities are : 0 == undefined, 2 == false and 1 == true for the correct answer
  correctAnswer?: number;
  answersWithImages?: boolean;
  answers: Answer[] = [];
  categories?: Category[] = [];

  constructor(questionTranslations: QuestionTranslation[], media: Media, correctAnswer: number, answersWithImages: boolean, answers: Answer[]) {
    this.questionTranslations = questionTranslations;
    this.mediaId = media.id;
    this.correctAnswer = correctAnswer;
    this.answersWithImages = answersWithImages;
    this.answers = answers;
  }
}

export class QuestionTranslation {
  translatedEntityId!: number;
  languageId!: number;
  question!: string;
  answerExplanation!: string;

  constructor(translatedEntityId: number, languageId: number, question: string, answerExplanation: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.question = question;
    this.answerExplanation = answerExplanation;
  }
}

export class Answer {
  id?: number;
  answerTranslations: AnswerTranslation[] = [];
  media?: Media;
  mediaId?: number;
}

export class AnswerTranslation {
  translatedEntityId!: number;
  languageId!: number;
  answer!: string;
  answerExplanation!: string;

  constructor(translatedEntityId: number, languageId: number, answer: string, answerExplanation: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.answer = answer;
    this.answerExplanation = answerExplanation;
  }
}

export class ModuleCustomTemplate {
  id!: number;
  moduleKey!: string;
  name!: string;
  description: string;
  projects: Project[] = [];

  constructor(id: number, moduleKey: string | undefined, name: string | undefined, description: string | undefined) {
    this.id = id;
    this.moduleKey = moduleKey || "";
    this.name = name || "";
    this.description = description || "";
  }
}

export class ModuleCustomAssociation {
  moduleId!: number;
  projectId!: number;
  isAssociated!: boolean;

  constructor(moduleId: number, projectId: number, isAssociated: boolean) {
    this.moduleId = moduleId;
    this.projectId = projectId;
    this.isAssociated = isAssociated;
  }
}

export class Category {
  categoryTranslations: CategoryTranslation[] = [];
  color!: string;
  icon!: string;
  answers: AnswerParticipatory[] = [];

  constructor(categoryTranslations: CategoryTranslation[], color: string, icon: string) {
    this.categoryTranslations = categoryTranslations;
    this.color = color;
    this.icon = icon;
  }
}

export class CategoryTranslation {
  translatedEntityId!: number;
  languageId!: number;
  category!: string;

  constructor(translatedEntityId: number, languageId: number, category: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.category = category;
  }
}

export class AnswerParticipatory {
  answerTranslations: AnswerParticipatoryTranslation[] = [];

  constructor(answerTranslations: AnswerParticipatoryTranslation[]) {
    answerTranslations: answerTranslations;
  }
}

export class AnswerParticipatoryTranslation {
  translatedEntityId!: number;
  languageId!: number;
  answer!: string;

  constructor(translatedEntityId: number, languageId: number, answer: string) {
    this.translatedEntityId = translatedEntityId;
    this.languageId = languageId;
    this.answer = answer;
  }
}