import { ChangelogItem } from "app/Models/newFunctionalityChangelog";

// We update this list when we push our changes to the production branch. If you're pushing to prod you have to:
// - Make sure that the list is readable for our final user (non technical people).
// - Add the release date to the first element of the list.
// - Copy the model to the top of the list
//
// Whenever you develop a new functionality, you should add it in the corresponding list:
// - Added for new features.
// - Changed for changes in existing functionality.
// - Fixed for any bug fixes.
// - Removed for now removed features.
// Make sure that the functionality is readable for our final user (non technical people).
// Try to follow the current writing style.
//
// We follow the Keep A Changelog model : https://keepachangelog.com/en/1.1.0/

/* MODEL TO COPY
{
  releaseDate: null,
  functionalities: {
    added: [] ,
    changed: [],
    fixed: [],
    removed: [],
  }
},
*/

export const newFunctionalitiesList: ChangelogItem[] = [
  {
    releaseDate: new Date(2025, 2, 6),
    functionalities: {
      added: [
        "Bibliothèque : ajout de la génération des miniatures pendant la publication. Les miniatures sont disponibles dans l'application depuis la version du 06 mars 2025.",
        "Itinéraire, Activité, Jalon: Ajout de la pastille 'prêt pour publication'"
      ] ,
      changed: [],
      fixed: [
        "Corrections de failles de sécurité.",
        "Correction d'un bug qui impactait la mise à jour des images lors d'une publication.",
        "Correction d'un bug qui empêchait l'ouverture des liens d'accès à une activité ou une étape depuis le pop up d'échec de publication.",
        "Correction d'un bug lorsqu'une langue est ajoutée après la création d'un module"
      ],
      removed: [],
    }
  },
  {
    releaseDate: new Date(2025, 1, 13),
    functionalities: {
      added: [],
      changed: [
        "Général : Le CMS OHRIZON devient OHRIZON+. La charte graphique a également changé pour mieux représenter les couleurs de l'entreprise.",
        "Général : Le design de la page de connexion a été amélioré. Les termes anglais ont été remplacés par des termes français.",
        "Général : Certains textes ont été changés dans le CMS.",
        "Parcours : La suppression d'un parcours a été sécurisée afin de limiter les erreurs de suppression. Il faut désormais écrire le nom du parcours pour confirmer sa suppression.",
        "Parcours et Etape: La section Contenu a été réorganisée. Le nom et les détails sont alignés sur la droite de la section.",
        "Parcours, Etape et Activité: Le nom des boutons créer a été changé pour sauvegarder sur les pages Parcours, Étape et Activité lors de la première création de ceux-ci.",
        "Activité : L'activié 'Galerie Multimédia' a été renommée en 'Ressources'.",
        "Activité : L'activié Quiz incrémental a été renommée en 'Quiz de correspondance'.",
        "Activité Quiz de correspondance : Cette activité peut maintenant être associée à une étape et est disponible sur l'application.",
        "Prévisualisation : Le design de la prévisualisation a été amélioré afin de reproduire au maximum le comportement de l'application mobile.",
      ],
      fixed: ["Correction d'un bug qui empêchait la suppression des parcours.", "Correction d'un bug qui empêchait le téléchargement de gros fichiers."],
      removed: [],
    },
  },
  {
    releaseDate: new Date(2025, 0, 8),
    functionalities: {
      added: [
        "Activité : Le quiz incrémental est maintenant disponible (uniquement sur le CMS, pas encore sur l'application mobile). L'association a une étape n'est pas encore possible pour éviter des erreurs lors de la publication.",
        "Prévisualisation : Une première version de la prévisualisation est disponible sur le CMS. Celle-ci permet de simuler l'affichage de la tablette depuis le CMS. Cela permet de pointer les textes ou images manquantes. La prévisualisation est fidèle à l'affichage de la tablette cependant certains éléments peuvent être légèrement différents.",
      ],
      changed: [],
      fixed: [],
      removed: [],
    },
  },
  {
    releaseDate: new Date(2024, 11, 18),
    functionalities: {
      added: [
        "Projets : Un compte utilisateur peut être lié à plusieurs projets et accéder à ceux-ci depuis la barre de navigation.",
        "Entête de l'application: Une barre horizontale d'entête a été ajoutée dans l'application, permettant de visualiser le nom du projet ainsi que les informations de l'utilisateur connecté.",
      ],
      changed: [
        "Authentification : Nouvelle méthode d'authentification avec un compte personnel, possibilité de réinitialiser son mot de passe via un lien par courriel",
        "Parcours : fenêtre popup d'ajout de langues améliorée.",
        "Activité : Choix du type d'activité amélioré lors de la création d'une nouvelle activité.",
      ],
      fixed: [],
      removed: [],
    },
  },
  {
    releaseDate: new Date(2024, 10, 14),
    functionalities: {
      added: [],
      changed: ["Activité quiz : les questions sont maintenant dépliées par défaut à l'ouverture de la page."],
      fixed: [
        "Activité : correction d'un bug qui empêchait parfois la sauvegarde des activités.",
        "Activité quiz : correction d'un bug d'affichage des données lors de la sauvegarde d'une activité quiz. Les données étaient bien sauvegardées mais il fallait rafraichir la page pour les voir.",
      ],
      removed: [],
    },
  },
  {
    releaseDate: new Date(2024, 10, 7),
    functionalities: {
      added: [
        "Activité : Lors de la création d'une nouvelle activité depuis la page étape, le champ 'Étape' est rempli automatiquement.",
        "Activité : Ajout de la possibilité d'ajouter plusieurs médias en même temps lorsqu'on crée une activité du type galerie multimédia.",
        "Activité : Il est désormais possible d'ajouter plusieurs médias en même temps",
        "Activité : Possibilité de prévisualiser un média pendant son ajout",
        "Activité : Ajout d'une barre de recherche dans le menu déroulant 'Étape associée'.",
        "Activité : La langue est automatiquement sélectionnée à la création s'il n'y en a qu'une de disponible.",
        "Activité Galerie : Il est désormais possible d'ajouter la traduction d'un média, au lieu d'utiliser le label défini dans la bibliothèque.",
        "Activité et Étape : Ajout d'un d'un fil d'ariane.",
        "Liste d'activités : Ajout d'une barre de recherche .",
        "Liste d'activités : Il est désormais possible d'ouvrir une activité dans un nouvel onglet (ctrl + clic ou clic molette)",
        "Bibliothèque : Ajout d'une barre de recherche.",
        "Bibliothèque : Il est désormais possible d'annuler l'envoi d'un média.",
        "Bibliothèque : Il est désormais d'ajouter des médias à upload avec un copier-coller (ctrl+V).",
        "Parcours : Ajout d'une carte permettant de visualiser et placer les étapes associées à ce parcours.",
        "Étape : Ajout d'une carte permettant de visualiser et placer la localisation de l'étape associée à la page.",
      ],
      changed: [
        "Bibliothèque : Lors de la modification du nom d'un média après un upload, il est désormais possible de valider en appuyant sur entrée",
        "Parcours : Agrandissement du menu déroulant pour sélectionner une étape ou un type d'activité spéciale.",
        "Activité spéciale : Agrandissement du menu déroulant pour sélectionner un type d'activité spéciale.",
        "Général : Le nom de l'onglet du navigateur change désormais en fonction de la page ou l'on se trouve.",
        "Général : L'état déplié/ouvert du menu latéral est désormais sauvegardé.",
      ],
      fixed: ["Amélioration du système de publication, il devrait être plus robuste. Auparavant un itinéraire pouvait afficher 'publication en cours' indéfiniment."],
      removed: [],
    },
  },
];
