import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { fileType } from "app/Enumerations/file-type.enum";
import { ItineraryDetail } from "app/Models/itineraryDetail";
import { Language } from "app/Models/language";
import { Media } from "app/Models/media";
import { MediaAssociation } from "app/Models/module";
import { PointOfInterest } from "app/Models/point-of-interest";
import { DataService } from "app/Services/Data-Service/data.service";
import { InputText } from "primeng/inputtext";
import { ToggleSwitchModule } from "primeng/toggleswitch";
import { PointOfInterestMapComponent } from "../../../ReusableComponents/point-of-interest-map/point-of-interest-map.component";
import { AddLanguagePopupComponent } from "../../../Popups/add-language-popup/add-language-popup/add-language-popup.component";
import { MediaAssociationComponent } from "app/Popups/media-association/media-association.component";

@Component({
    selector: "app-itinerary-configuration",
    templateUrl: "./itinerary-configuration.component.html",
    styleUrls: ["./itinerary-configuration.component.css"],
    imports: [MediaAssociationComponent, FormsModule, ReactiveFormsModule, InputText, ToggleSwitchModule, PointOfInterestMapComponent, AddLanguagePopupComponent]
})
export class ItineraryConfigurationComponent implements OnChanges, OnInit {
  @Input() informationsForm!: FormGroup;
  @Input() selectedLanguages: Language[] = [];
  @Input() languagesList: Language[] = [];
  @Input() itinerary?: ItineraryDetail;
  @Input() isCreation: boolean = false;

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeCoordinates: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();
  @Output() changeItineraryImage: EventEmitter<Media> = new EventEmitter<Media>();

  visibleLanguages: boolean = false;
  showMap: boolean = false;

  // Popup
  visibleMediaPopup: boolean = false;
  mediaVisualisationFromPage = false;
  mapImage?: MediaAssociation;
  fileBlob?: Blob;
  fileUrl?: string;
  mediaList: Media[] = [];

  languageIdToDelete: number = 0;

  inputLength = inputLength;

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,) {
    this.informationsForm = this.formBuilder.group({
      itineraryLabel: ["", Validators.required],
      languages: [""],
      isLocated: [""],
    });
  }

  ngOnInit() {
    const storedState = localStorage.getItem("showMapState");
    if (storedState && !this.isCreation) {
      this.showMap = JSON.parse(storedState);
    }
    this.dataService.getMedias().subscribe({
      next: (medias) => {
        this.mediaList = medias;
      },
      error: (error) => {
        console.log(error.message);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["languagesList"] && this.languagesList.length === 1 && this.selectedLanguages.length === 0) {
      // Hack to fix a NG0100 error
      // There might be a better way to do this? Should we use @Input/@Ouptut with an event? to the languageList?
      // TODO: investigate this
      setTimeout(() => {
        this.languagesChanged([this.languagesList[0].id]);
      }, 0);
    }
    if (changes["itinerary"] && this.itinerary?.itineraryImage && !this.fileUrl) {
      this.mapImage = new MediaAssociation(0, this.itinerary?.itineraryImage, false, 0, false, []);
      this.dataService.getFile(this.mapImage!.media).subscribe({
        next: (file) => {
          this.fileBlob = file;
          this.fileUrl = URL.createObjectURL(file);
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    }
  }

  languagesChanged(languageIds: number[]) {
    // TODO: clean up these condition if they will not be used
    if (languageIds.length == 0) {
      // console.log('liste vide');
    } else if (languageIds[0] == -1) {
      // console.log('juste fermer la liste');
    } else {
      languageIds.forEach((id) => {
        // const index = this.languagesList.findIndex(l => l.id == id);
        this.languageChangedEmitter.emit(id);
        // this.selectedLanguages.push(this.languagesList[index]);
      });
    }
    this.closePopup();
  }

  showPopupLanguageDeletion(idLanguage: number) {
    this.languageIdToDelete = idLanguage;
    this.visibleLanguages = true;
  }

  closePopup() {
    this.visibleLanguages = false;
    this.languageIdToDelete = 0;
  }

  extendMap(state: boolean) {
    this.showMap = state;
    localStorage.setItem("showMapState", JSON.stringify(this.showMap));
  }

  countLabelCharacters(): string {
    if (this.informationsForm.get(["itineraryLabel"])?.value) {
      return this.informationsForm.get(["itineraryLabel"])?.value.length;
    } else {
      return "0";
    }
  }

  isLocated(): boolean {
    if (this.informationsForm.get(["isLocated"])?.value) {
      return true;
    }
    return false;
  }

  visualizeMapImage() {
    this.mediaVisualisationFromPage = true;
    this.visibleMediaPopup = true;
  }

  removeMapImage() {
    this.mapImage = undefined;
    this.fileUrl = undefined;
    this.changeItineraryImage.emit(undefined);
  }

  addMapImage() {
    this.mediaList = this.mediaList.filter((m) => m.fileType === fileType.Image);
    this.visibleMediaPopup = true;
  }

  associateMedia(media: Media) {
      this.closeMediaPopup();
      this.changeItineraryImage.emit(media);
      this.mapImage = new MediaAssociation(0, media, false, 0, false, []);
      this.dataService.getFile(this.mapImage!.media).subscribe({
        next: (file) => {
          this.fileBlob = file;
          this.fileUrl = URL.createObjectURL(file);
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    }

  closeMediaPopup() {
    this.visibleMediaPopup = false;
    this.mediaVisualisationFromPage = false;
  }
}
