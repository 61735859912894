<div class="card">
  <div class="header">
    <h2 title="Cette section sert à configurer le contenu qui sera visible par l'utilisateur">Contenu de l'activité<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>
    @for (language of languagesSelected; track language.id) {
      <app-language-state [language]="language" [languageState]="showLanguageState(language.id)"> </app-language-state>
    }
  </div>

  @if (languagesSelected.length === 0) {
    <p>Aucune langue n'est associée à l'activité.</p>
  } @else {
    <form [formGroup]="multiLanguageForm">
      <div class="horizontal-display">
        <div class="form-field">
          <label htmlFor="moduleName">Langue en cours de modification</label>
          <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p>
          <p-select [options]="languagesSelected" optionValue="id" optionLabel="name" formControlName="currentLanguage" (onChange)="setLanguage($event.value)" />
        </div>
        <div class="form-field">
          <span class="">
            <label htmlFor="moduleName">Nom de l'activité</label>
            <p class="field-explanation">Comment souhaitez-vous nommer votre activité</p>
            <input pInputText id="moduleName" formControlName="moduleName" (change)="saveModuleNameAndSynthesis()" [maxlength]="inputLength.maxModuleName" />
            <small class="char-count"> ({{ countNameCharacters() }}/{{ inputLength.maxModuleName }}) </small>
          </span>
        </div>
      </div>
    </form>

    @if (moduleType === enumModuleType.QuizTrueFalse
      || moduleType === enumModuleType.QuizMultipleChoice
      || moduleType === enumModuleType.QuizIncremental
      || moduleType === enumModuleType.QuizParticipatory) {

      @if (moduleType === enumModuleType.QuizTrueFalse
      || moduleType === enumModuleType.QuizMultipleChoice) {
        <div class="horizontal-display">
          <h3>Questions</h3>
          <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle question" (click)="addQuestion()"> </i>
        </div>

        @if (quizQuestions.length === 0) {
          <p>Aucune question n'est associée à cette activité.</p>
        }
      }

      @if(moduleType === enumModuleType.QuizParticipatory) {
        <div class="horizontal-display configuration-header grey-color">
          @if (showConfiguration) {
            <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier question" (click)="showConfiguration = false"> </i>
          } @else {
            <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier question" (click)="showConfiguration = true"> </i>
          }
          <h4>Configuration du quiz</h4>
          <app-completion-state
            class="margin-left"
            [completionState]="participatoryQuizCompletion()"
            [tooltipTrue]="'La configuration du quiz est valide'"
            [tooltipFalse]="'La configuration du quiz n\'est pas valide'">
          </app-completion-state>
        </div>
        @if (showConfiguration) {

          <div class="configuration-participatory">

            <div class="infos-quiz">
              <form [formGroup]="trueFalseQuizForm">
                <div class="margin-bottom" formArrayName="items">
                  <label htmlFor="timer">Comportement de l'Icône</label>
                  <p class="field-explanation">Voulez-vous que l'icône grandisse ou rapetisse ?</p>

                  <div class="radio-buttons" formGroupName="0">
                    <div class="flex align-items-center" title="Lorsque les participants auront une bonne réponse, l’icône sur leur écran va grandir (pour atteindre la taille maximale après 4 bonnes réponses)">
                      <input id="true" class="growingIcon" type="radio" value="true" formControlName="growingIcon" (change)="growingIconChanged()" />
                      <label for="true" class="ml-2 growingIcon">Grandissante</label>
                    </div>

                    <div class="flex align-items-center margin-left" title="Lorsque les participants auront une bonne réponse, l’icône sur leur écran va rapetisser (pour atteindre la taille minimale après 4 bonnes réponses)">
                      <input id="false" class="growingIcon" type="radio" value="false" formControlName="growingIcon" (change)="growingIconChanged()" />
                      <label for="false" class="ml-3 growingIcon">Rapetissante</label>
                    </div>
                  </div>
                </div>
              </form>

              <div class="margin-top timer">
                <label htmlFor="timer">Timer (facultatif)</label>
                <p class="field-explanation">Choisissez le temps de la durée de votre quiz</p>

                <div class="horizontal-display margin-bottom timer">

                  <p-inputnumber inputId="integeronly" [(ngModel)]="timerDecompositionMinutes"
                  [min]="0"
                  [max]="59"
                  (onBlur)="onChangeInputTimer()"/>
                  <p class="time-separation">:</p>
                  <p-inputnumber inputId="integeronly" [(ngModel)]="timerDecompositionSeconds"
                  [min]="0"
                  [max]="59"
                  (onBlur)="onChangeInputTimer()"/>

                </div>
                <p>Durée sélectionnée : {{ timerDecompositionMinutes }} min {{ timerDecompositionSeconds }} secs.</p>

              </div>
            </div>

            <div class="infos-quiz">
              <form [formGroup]="participatoryQuizForm">
              <label htmlFor="timer">Thème du quiz</label>
              <p class="field-explanation">Choisissez un arrière-plan ainsi qu'une palette de couleurs</p>
              <div class="item-infos-quiz margin-top">
                <p>Arrière-plan</p>
                <div class="horizontal-display color-palette-container">
                  @for (color of backgroundColors; let indexBackground = $index; track indexBackground) {
                    <input type="radio" [value]="indexBackground" formControlName="backgroundColor" (change)="changeBackgroundColor(indexBackground)"/>
                    <div class="color-visualisation"
                      tabindex="0"
                      [style]="{ 'background-color': color }">
                    </div>
                  }
                </div>
              </div>
              <div class="item-infos-quiz margin-top">
                 <p>Couleurs de catégories</p>
                 @for (colorArray of categoryColors; let paletteIndex = $index; track paletteIndex) {
                    <div class="horizontal-display color-palette-container">
                      <input [id]="'color-palette' + paletteIndex" type="radio" [value]="paletteIndex" formControlName="colorPalette" (change)="changeColorPalette(paletteIndex)" />
                      @for (color of colorArray; let colorIndex = $index; track colorIndex) {
                        <div class="color-visualisation" [title]="'Couleur de la ' + (colorIndex + 1) + 'è catégorie'" [style]="{'background-color': color}">
                          <p class="color-palette-item">{{ colorIndex + 1 }}e</p>
                        </div>
                      }
                    </div>
                  }
              </div>
            </form>
            </div>
          </div>
        }
      }

      <form [formGroup]="trueFalseQuizForm">
        <div formArrayName="items">
          @for (quizQuestion of quizQuestions; let indexQuestion = $index; track indexQuestion) {
            <div class="question-card">
              @if (moduleType !== enumModuleType.QuizIncremental) {
                <div class="horizontal-display grey-color">
                  <div class="header-question">
                    @if (showQuestion[indexQuestion]) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier question" (click)="showAndSetQuestion(false, indexQuestion)"> </i>
                    } @else {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier question" (click)="showAndSetQuestion(true, indexQuestion)"> </i>
                    }
                    @if (moduleType === enumModuleType.QuizParticipatory) {
                      <h4>Contenu</h4>
                      @for (language of languagesSelected; track language) {
                        <app-language-state [isSmall]="true" [language]="language" [languageState]="showLanguageStateQuestion(language.id, indexQuestion)"> </app-language-state>
                      }
                    } @else {
                      <h4>Question {{ indexQuestion + 1 }}</h4>
                      @for (language of languagesSelected; track language) {
                        <app-language-state [isSmall]="true" [language]="language" [languageState]="showLanguageStateQuestion(language.id, indexQuestion)"> </app-language-state>
                      }

                      @if (quizQuestions[indexQuestion].correctAnswer !== enumCorrectAnswer.None) {
                        <i tabIndex="0" aria-hidden="true" class="bi bi-check-circle-fill ready-to-publish margin-left" title="Le choix de réponse est bien renseigné"> </i>
                      } @else {
                        <i tabIndex="0" aria-hidden="true" class="bi bi-x-circle-fill not-ready-to-publish margin-left" title="Le choix de réponse n'est pas renseigné"> </i>
                      }

                      @if ((quizQuestions[indexQuestion].media !== undefined && quizQuestions[indexQuestion].media !== null) || quizQuestions[indexQuestion].answersWithImages) {
                        @if (displayImageCompletionState(indexQuestion)) {
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image ready-to-publish medium margin-left" title="La question contient des images"></i>
                        } @else {
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image not-ready-to-publish medium margin-left" title="La question contient des images manquantes"> </i>
                        }
                      }
                    }
                  </div>
                  @if (moduleType !== enumModuleType.QuizParticipatory) {
                    <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer la question" (click)="removeQuestion(indexQuestion)"> </i>
                  }
                </div>
              }

              @if (showQuestion[indexQuestion] || moduleType === enumModuleType.QuizIncremental) {
                <div class="question-content-card" [formGroupName]="indexQuestion">
                  <div class="horizontal-display margin-bottom">
                    <div class="question-field">
                      <span>
                        <label htmlFor="questionInput">Intitulé de la question</label>
                        <p class="field-explanation">Quelle question voulez-vous poser à l'utilisateur</p>
                        <textarea
                          id="questionInput"
                          rows="1"
                          pTextarea
                          formControlName="questionInput"
                          autoResize="true"
                          [maxlength]="inputLength.maxQuestionInput"
                          (change)="saveChange(indexQuestion)">
                        </textarea>
                        <small class="char-count"> ({{ countQuestionCharacters(indexQuestion) }}/{{ inputLength.maxQuestionInput }}) </small>
                      </span>
                    </div>

                    @if (moduleType === enumModuleType.QuizTrueFalse || moduleType === enumModuleType.QuizMultipleChoice) {
                      @if (quizQuestions[indexQuestion].media !== undefined && quizQuestions[indexQuestion].media !== null) {
                        <div class="image-association grey-color">
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageQuestion(indexQuestion)"> </i>
                          <p class="image-name truncate-two-lines">{{ quizQuestions[indexQuestion].media?.name }}</p>
                          <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Désassocier l'image" (click)="removeImageQuestion(indexQuestion)"> </i>
                        </div>
                      } @else {
                        <div class="image-association grey-color">
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Associer une image" (click)="addImageQuestion(indexQuestion)"></i>
                          <p class="image-name">Aucune image associée</p>
                        </div>
                      }
                    }
                  </div>

                  @if (moduleType === enumModuleType.QuizTrueFalse) {
                    <label htmlFor="correctAnswer">Bonne réponse</label>
                    <p class="field-explanation">Choisissez quelle réponse sera la réponse valide pour l'utilisateur.</p>

                    <div class="radio-buttons">
                      <div class="flex align-items-center">
                        <input [id]="'true' + indexQuestion" type="radio" value="true" formControlName="correctAnswer" (change)="saveChange(indexQuestion)" />
                        <label [for]="'true' + indexQuestion" class="ml-2">Vrai</label>
                      </div>

                      <div class="flex align-items-center margin-left">
                        <input [id]="'false' + indexQuestion" type="radio" value="false" formControlName="correctAnswer" (change)="saveChange(indexQuestion)" />
                        <label [for]="'false' + indexQuestion" class="ml-3">Faux</label>
                      </div>
                    </div>
                  } @else if (moduleType === enumModuleType.QuizParticipatory) {

                  } @else {
                    <div class="horizontal-display">
                      <label for="idk">Réponses</label>
                      @if (moduleType !== enumModuleType.QuizIncremental) {
                        <div>
                          <p-checkbox [binary]="true" inputId="image-answer" title="" formControlName="answersWithImage" (onChange)="answerWithImage($event, indexQuestion)" />
                          <label for="image-answer" class="choice-image-answers">Réponse avec image</label>
                        </div>
                      }

                      <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle réponse" (click)="addAnswer(indexQuestion)"> </i>
                    </div>
                    @for (answer of quizQuestion.answers; let indexAnswer = $index; track indexAnswer) {
                      <div class="answer-quiz">
                        @if (moduleType !== enumModuleType.QuizIncremental) {
                          <input
                            [id]="'false' + indexQuestion"
                            type="radio"
                            title="Choix de bonne réponse"
                            [value]="indexAnswer + 1"
                            formControlName="correctAnswer"
                            (change)="saveChoiceAnswer(indexQuestion, indexAnswer)" />
                        }

                        <div class="textarea" formArrayName="answers">
                          @if (moduleType === enumModuleType.QuizIncremental && indexAnswer === 0) {
                            <p class="margin-left">Intitulé de la réponse</p>
                          }

                          <div class="flex-display">
                            @if (moduleType === enumModuleType.QuizIncremental) {
                              <p class="index-answer">{{ indexAnswer + 1 }}</p>
                              <textarea
                                rows="1"
                                pTextarea
                                [formControlName]="indexAnswer"
                                autoResize="true"
                                [maxlength]="inputLength.maxAnswerIncrementalInput"
                                (change)="saveAnswer(indexQuestion, indexAnswer)">
                              </textarea>
                              <small class="char-count-inline"> ({{ countAnswerCharacters(indexQuestion, indexAnswer) }}/{{ inputLength.maxAnswerIncrementalInput }}) </small>
                            } @else {
                              <textarea rows="1" pTextarea [formControlName]="indexAnswer" autoResize="true" [maxlength]="inputLength.maxAnswerInput" (change)="saveAnswer(indexQuestion, indexAnswer)">
                              </textarea>
                              <small class="char-count-inline"> ({{ countAnswerCharacters(indexQuestion, indexAnswer) }}/{{ inputLength.maxAnswerInput }}) </small>
                            }
                          </div>
                        </div>

                        @if (moduleType === enumModuleType.QuizIncremental) {
                          <div class="textarea margin-left-small" formArrayName="answerExplanation">
                            @if (moduleType === enumModuleType.QuizIncremental && indexAnswer === 0) {
                              <p class="margin-left-small">Explication de la réponse (facultatif)</p>
                            }
                            <div class="horizontal-display">
                              <textarea rows="1" pTextarea [formControlName]="indexAnswer" (change)="saveAnswer(0, indexAnswer)" autoResize="true" [maxlength]="inputLength.maxAnswerExplanation">
                              </textarea>
                              <small class="char-count-inline">({{ countExplanationIncrementalCharacters(indexAnswer) }}/{{ inputLength.maxAnswerExplanation }})</small>
                            </div>
                          </div>
                        }

                        @if (quizQuestion.answersWithImages === true || moduleType === enumModuleType.QuizIncremental) {
                          @if (answer.media !== undefined && answer.media !== null) {
                            <div class="image-association grey-color">
                              <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageAnswer(indexQuestion, indexAnswer)"> </i>
                              <p class="image-name truncate-two-lines" [title]="'Nom du média: ' + answer.media.name + answer.media.extension">{{ answer.media.name }}</p>
                              <i
                                tabIndex="0"
                                aria-hidden="true"
                                class="bi bi-x-lg image-buttons small remove-image"
                                title="Désassocier l'image"
                                (click)="removeImageAnswer(indexQuestion, indexAnswer)">
                              </i>
                            </div>
                          } @else {
                            <div class="image-association grey-color">
                              <i
                                tabIndex="0"
                                aria-hidden="true"
                                class="bi bi-card-image image-buttons not-ready-to-publish"
                                title="Associer une image"
                                (click)="addImageAnswer(indexQuestion, indexAnswer)"></i>
                              <p class="image-name">Aucune image associée</p>
                            </div>
                          }
                        }

                        @if (quizQuestion.answers && quizQuestion.answers.length > 2) {
                          <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer la réponse" (click)="removeAnswer(indexQuestion, indexAnswer)"> </i>
                        }
                      </div>
                      <!-- @if (moduleType === enumModuleType.QuizIncremental) {
                      <div class="textarea" formArrayName="answerExplanation">
                        <textarea rows="1" pTextarea [formControlName]="indexAnswer" (change)="saveAnswer(0, indexAnswer)" autoResize="true" maxlength="150"> </textarea>
                      </div>
                    } -->
                    }
                  }

                  @if (moduleType === enumModuleType.QuizTrueFalse || moduleType === enumModuleType.QuizMultipleChoice) {
                    <div class="margin-top">
                      <label for="idk">Explication de la réponse (facultatif)</label>
                      <textarea rows="1" pTextarea formControlName="answerExplanation" autoResize="true" [maxlength]="inputLength.maxAnswerExplanation" (change)="saveChange(indexQuestion)">
                      </textarea>
                      <p class="right-align">({{ countExplanationCharacters(indexQuestion) }}/{{ inputLength.maxAnswerExplanation }})</p>
                    </div>
                  }
                  @if (moduleType === enumModuleType.QuizParticipatory) {

                    <div class="border"></div>

                    <div class="horizontal-display">
                      <h4>Catégories</h4>
                      <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle catégorie" (click)="addCategory()"> </i>
                    </div>
                    <div formArrayName="categories">
                      @for (category of categoriesArray.controls; track category; let indexCategory = $index) {
                        <div [formGroupName]="indexCategory">

                          @if (indexCategory !== 0) {
                            <div class="border"></div>
                          }

                          <div class="horizontal-display category-header">
                            @if (showCategory[indexCategory]) {
                              <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier catégorie" (click)="displayCategory(false, indexCategory)"> </i>
                            } @else {
                              <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier catégorie" (click)="displayCategory(true, indexCategory)"> </i>
                            }
                            <h4 class="category-title">Catégorie {{ indexCategory + 1 }} </h4>
                            @if (quizQuestions[0].categories && quizQuestions[0].categories.length > 1) {
                              <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Supprimer la catégorie" (click)="removeCategoryPopup(indexCategory)"> </i>
                            }
                          </div>

                          @if (showCategory[indexCategory]) {
                            <label htmlFor="categoryInput">Intitulé de la Catégorie</label>
                            <p class="field-explanation">Quel est le nom de la catégorie de réponses</p>
                            <textarea
                              id="categoryInput"
                              rows="1"Fx
                              pTextarea
                              formControlName="name"
                              autoResize="true"
                              [maxlength]="inputLength.maxCategoryInput"
                              (change)="saveCategory(indexCategory)">
                            </textarea>
                            <small class="char-count"> ({{ countCategoryCharacters(indexCategory) }}/{{ inputLength.maxCategoryInput }}) </small>
                            <div class="horizontal-display align-top">
                              <span>
                                <label htmlFor="color">Couleur</label>
                                <p class="field-explanation">Visualisez la couleur de la catégorie de réponses</p>
                                @if (quizQuestions[0].categories![indexCategory].color === '') {
                                  <p class="not-ready-to-publish">Choisir une palette de couleurs</p>
                                } @else {
                                  <div class="color-visualisation" [style]="{'background-color': quizQuestions[0].categories![indexCategory].color}"></div>
                                }
                              </span>
                              <span>
                                <label htmlFor="icon">Icône (facultatif)</label>
                                <p class="field-explanation">Choisissez l'icône de la catégorie de réponses</p>
                                @if (quizQuestions[0].categories![indexCategory].icon) {
                                  <i [class]="quizQuestions[0].categories![indexCategory].icon" class="bi icon"></i>
                                }
                                <button class="button" (click)="setIconPickerPopup(indexCategory)">Choisir</button>
                                <button class="button" (click)="removeIcon(indexCategory)">Supprimer</button>
                              </span>
                            </div>
                            <div class="category-answer-container">
                              <div class="horizontal-display">
                                <div>
                                  <label htmlFor="categoryInput">Réponses</label>
                                  <p class="field-explanation">Renseignez les réponses ci dessous.</p>
                                </div>
                                <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle réponse" (click)="addAnswerParticipatoryQuiz(indexCategory)"> </i>
                              </div>

                              <div formArrayName="answers">
                                @for(answer of getAnswersArray(category).controls; let indexAnswer = $index; track $index) {
                                  <div class="grid-row">
                                    <textarea
                                      id="categoryInput"
                                      rows="1"
                                      pTextarea
                                      [formControlName]="indexAnswer"
                                      autoResize="true"
                                      [maxlength]="inputLength.maxAnswerInput"
                                      (change)="saveCategoryAnswerChange(indexCategory, indexAnswer)">
                                    </textarea>

                                    <small class="char-count"> ({{ countAnswerParticipatoryCharacters(indexCategory, indexAnswer) }}/{{ inputLength.maxAnswerInput }}) </small>
                                    @if (quizQuestions[0].categories![indexCategory].answers.length > 2) {
                                      <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="supprimer la question" (click)="removeAnswerParticipatoryQuiz(indexCategory, indexAnswer)"> </i>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          }

                        </div>
                      }
                    </div>
                  }
                </div>
              }

            </div>
          }
        </div>
      </form>

      <h3>Synthèse du quiz (facultatif)</h3>

      <div class="horizontal-display">
        <div class="text-synthesis">
          <textarea rows="1" pTextarea [formControl]="textSynthesis" (change)="saveModuleNameAndSynthesis()" autoResize="true" [maxLength]="inputLength.maxQuizSynthesis"></textarea>
          <p class="char-count">({{ countSynthesisCharacters() }}/{{ inputLength.maxQuizSynthesis }})</p>
        </div>

        @if (imageSynthesis !== undefined) {
          <div class="image-association grey-color">
            <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageSynthesis()"> </i>
            <p class="image-name truncate-two-lines" [title]="'Nom du média: ' + imageSynthesis.name + imageSynthesis.extension">{{ imageSynthesis.name }}</p>
            <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Désassocier l'image" (click)="removeImageSynthesis()"> </i>
          </div>
        } @else {
          <div class="image-association grey-color">
            <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Associer une image" (click)="addImageSynthesis()"></i>
            <p class="image-name">Aucune image associée</p>
          </div>
        }
      </div>
    }
  }
  @if (moduleType === enumModuleType.Gallery) {
    <app-module-gallery [multiLanguageForm]="multiLanguageForm" [mediaList]="mediaList" [languagesSelected]="languagesSelected"> </app-module-gallery>
  }
</div>

<app-media-association
  [visible]="visibleMediaPopup"
  [mediaList]="mediaList"
  [filteredMediaList]="mediaList"
  [mediaVisualisationFromPage]="mediaVisualisationFromPage"
  [mediaToDisplay]="selectedMedia"
  [fileUrl]="fileUrl"
  [fileBlob]="fileBlob"
  (closePopupEmitter)="closeMediaPopup()"
  (associatedMediaEmitter)="associateMedia($event)">
</app-media-association>

<app-icon-picker-popup
  [visible]="visibleIconPopup"
  [selectedIcon]="iconSelected"
  (sendIconNameEmitter)="closeIconPickerPopup($event)">
</app-icon-picker-popup>

<app-confirmation-popup
  [visible]="visibleConfirmationPopup"
  [confirmTypeAction]="enumConfirmAction.Delete"
  [entityTypeAction]="enumEntityType.Category"
  [confirmationLabel]="labelConfirmation"
  (confirmActionEmitter)="removeCategory($event)">
</app-confirmation-popup>
