import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { Module } from "app/Models/module";
import { PointOfInterestDetail } from "app/Models/PointOfInterestDetail";
import { DataService } from "app/Services/Data-Service/data.service";

@Component({
  selector: "app-point-of-interest-previsualisation",
  templateUrl: "./point-of-interest-previsualisation.component.html",
  styleUrl: "./point-of-interest-previsualisation.component.css",
})
export class PointOfInterestPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  pointOfInterest?: PointOfInterestDetail;

  enumModuleType = moduleType;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getPointOfInterest(this.entityId.toString()).subscribe((pointOfInterest) => {
        this.pointOfInterest = pointOfInterest;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
      });
    }
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.pointOfInterest?.pointOfInterestTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  displayName(module: Module): string | undefined {
    const selectedTranslation = module.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayPointOfInterestName(): string | undefined {
    const selectedTranslation = this.pointOfInterest?.pointOfInterestTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  backArrowClicked() {
    this.entityIdChanged.emit(this.pointOfInterest?.itineraryId);
    this.entityTypeChanged.emit("itinerary");
  }

  selectModule(module: Module) {
    this.entityIdChanged.emit(module.id);
    switch (module.type) {
      case moduleType.Gallery:
        this.entityTypeChanged.emit("gallery");
        break;

      case moduleType.Custom:
        this.entityTypeChanged.emit("custom");
        break;

      case moduleType.QuizIncremental:
        this.entityTypeChanged.emit("quizIncremental");
        break;

      case moduleType.QuizParticipatory:
        this.entityTypeChanged.emit('quizParticipatory');
        break;

      default:
        this.entityTypeChanged.emit("quiz");
        break;
    }
  }
}
