import { ApplicationConfig } from "@angular/core";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { providePrimeNG } from "primeng/config";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AuthInterceptor } from "./Interceptors/auth.interceptor";
import { MessageService } from "primeng/api";
import { ModuleService } from "./Services/Module-Service/module.service";
import { UploadFilesService } from "./Services/Upload-Service/upload-files.service";
import { DatePipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideAnimations } from "@angular/platform-browser/animations";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { MultiSelectModule } from "primeng/multiselect";
import { SelectModule } from "primeng/select";
import { InputSwitchModule } from "primeng/inputswitch";
import { TableModule } from "primeng/table";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ProgressBarModule } from "primeng/progressbar";
import { CheckboxModule } from "primeng/checkbox";
import { PaginatorModule } from "primeng/paginator";
import { TreeTableModule } from "primeng/treetable";
import { RadioButtonModule } from "primeng/radiobutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { LeafletModule } from "@bluehalo/ngx-leaflet";
import { BrowserModule } from "@angular/platform-browser";
import { importProvidersFrom } from "@angular/core";

import Lara from "@primeng/themes/lara";

//mypreset.ts
import { definePreset } from "@primeng/themes";

const MyPreset = definePreset(Lara, {
    semantic: {
        primary: {
            50:  "{blue.50}",
            100: "{blue.100}",
            200: "{blue.200}",
            300: "{blue.300}",
            400: "{blue.400}",
            500: "{blue.500}",
            600: "{blue.600}",
            700: "{blue.700}",
            800: "{blue.800}",
            900: "{blue.900}",
            950: "{blue.950}"
        },
        colorScheme: {
          light: {
            primary: {
              color: "{zinc.950}",
              inverseColor: "#ffffff",
              hoverColor: "{zinc.900}",
              activeColor: "{zinc.800}"
            },
            highlight: {
              background: "{zinc.950}",
              focusBackground: "{zinc.700}",
              color: "#ffffff",
              focusColor: "#ffffff"
            }
          },
          dark: {
            primary: {
              color: "{zinc.50}",
              inverseColor: "{zinc.950}",
              hoverColor: "{zinc.100}",
              activeColor: "{zinc.200}"
            },
            highlight: {
              background: "rgba(250, 250, 250, .16)",
              focusBackground: "rgba(250, 250, 250, .24)",
              color: "rgba(255,255,255,.87)",
              focusColor: "rgba(255,255,255,.87)"
            }
          }
        }
    }
});

export const appConfig: ApplicationConfig = {
    providers: [
      importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, InputTextModule, PasswordModule, ToastModule, DialogModule, MultiSelectModule, SelectModule
        , InputSwitchModule, TableModule, ProgressSpinnerModule, ProgressBarModule, CheckboxModule, PaginatorModule, TreeTableModule, RadioButtonModule, SplitButtonModule, LeafletModule),
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: MessageService },
      ModuleService,
      UploadFilesService,
      provideHttpClient(withInterceptorsFromDi()),
      DatePipe,
      provideAnimations(),
      provideAnimationsAsync(),
      providePrimeNG({
          theme: {
              preset: MyPreset,
              options: {
                // darkModeSelector: ".my-app-dark"
                darkModeSelector: "none"
              }
          }
      })
    ]
};
