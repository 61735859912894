import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { Module, ModuleCustomTemplate } from "app/Models/module";
import { DataService } from "app/Services/Data-Service/data.service";

@Component({
  selector: "app-custom-previsualisation",
  templateUrl: "./custom-previsualisation.component.html",
  styleUrl: "./custom-previsualisation.component.css",
})
export class CustomPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  module?: Module;

  moduleCustomTemplate?: ModuleCustomTemplate;

  enumModuleType = moduleType;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getModule(this.entityId.toString()).subscribe((module) => {
        this.module = module;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
        if (module.moduleCustomTemplateId) {
          this.dataService.getModuleCustomTemplate(module.moduleCustomTemplateId.toString()).subscribe((template) => {
            this.moduleCustomTemplate = template;
          })
        }
      })
    }
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  backArrowClicked() {
    if (this.module?.pointOfInterestId) {
      this.entityIdChanged.emit(this.module?.pointOfInterestId);
    }
    this.entityTypeChanged.emit("pointOfInterest");
  }
}
