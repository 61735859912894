import { Component, Input, OnInit } from '@angular/core';
import { CheckboxModule } from "primeng/checkbox";
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { ModuleCustomPopupComponent } from 'app/Popups/module-custom-popup/module-custom-popup.component';
import { MessageService } from 'primeng/api';
import { ModuleCustomAssociation, ModuleCustomTemplate } from 'app/Models/module';
import { Project } from 'app/Models/project';
import { InputText } from 'primeng/inputtext';
import { DataService } from 'app/Services/Data-Service/data.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-custom-modules',
  imports: [CheckboxModule, ReactiveFormsModule, InputText, FormsModule, ModuleCustomPopupComponent],
  templateUrl: './custom-modules.component.html',
  styleUrl: './custom-modules.component.css'
})
export class CustomModulesComponent implements OnInit {
  modeCreation = false;

  @Input() moduleCustomList: ModuleCustomTemplate[] = [];
  @Input() projects: Project[] = [];

  // Forms
  newModuleCustomForm: FormGroup;
  moduleCustomAssociationsBeforeModifications: boolean[][] = [];
  modifications: ModuleCustomAssociation[] = [];

  associations: boolean[][] = [];

  disableCheckboxes: boolean[][] = [];

  // Popup
  visiblePopup: boolean = false;

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private messageService: MessageService) {
      this.newModuleCustomForm = this.fb.group({
        moduleKey: ["", [Validators.required, Validators.minLength(inputLength.minModuleCustomKey)]],
        name: ["", [Validators.required, Validators.minLength(inputLength.minModuleCustomName)]],
        description: [""],
      });
  }

  ngOnInit() {

    forkJoin([this.dataService.getProjects(), this.dataService.getModuleCustomList()]).subscribe(([projects, modules]) => {
      this.projects = projects;
      this.moduleCustomList = modules;

      this.associations = this.moduleCustomList.map(() => this.projects.map(() => false));
      this.disableCheckboxes = this.moduleCustomList.map(() => this.projects.map(() => false));

      this.moduleCustomList.forEach((moduleCustom, moduleIndex) => {
        if (moduleCustom.projects.length > 0) {
          moduleCustom.projects.forEach((project) => {
            const projectIndex = this.projects.findIndex(p => p.id == project.id);
            this.associations[moduleIndex][projectIndex] = true;
            if (project.isModuleUsedInProject) {
              this.disableCheckboxes[moduleIndex][projectIndex] = true;
            }
          });
        }
      });

      this.moduleCustomAssociationsBeforeModifications = structuredClone(this.associations);
    });

  }

  disableCheckbox(moduleIndex: number, projectIndex: number): boolean {
    return this.moduleCustomList[moduleIndex].projects.find(p => p.id === this.projects[projectIndex].id)?.isModuleUsedInProject || false;
  }

  addRows(columnCount: number) {
    this.associations.push(new Array(columnCount).fill(false));
    this.moduleCustomAssociationsBeforeModifications.push(Array<boolean>(this.projects.length).fill(false));
    this.disableCheckboxes.push(Array<boolean>(this.projects.length).fill(false));
  }

  saveModuleAssociations() {
    for (let i = 0; i < this.moduleCustomList.length; i++) {
      for (let j = 0; j < this.projects.length; j++) {
        if (this.moduleCustomAssociationsBeforeModifications[i][j] !== this.associations[i][j]) {
          this.modifications.push(new ModuleCustomAssociation(this.moduleCustomList[i].id, this.projects[j].id, this.associations[i][j]));
        }
      }
    }

    if (this.modifications.length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Aucune modification", detail: "Aucun changement depuis la dernière sauvegarde" });
    } else {
      this.visiblePopup = true;
    }
  }

  processModuleAssociations(confirmAction: boolean) {
    this.visiblePopup = false;
    if (confirmAction) {
      this.dataService.updateModuleCustomAssociations(this.modifications).subscribe(() => {
        this.messageService.clear();
        this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "Les nouvelles associations ont été sauvegardées" });
      });

    }
    this.modifications = [];
  }

  createModuleCustom() {
    if (this.newModuleCustomForm.valid) {
      this.dataService
        .createModuleCustomTemplate(
          new ModuleCustomTemplate(0, this.newModuleCustomForm.get("moduleKey")?.value, this.newModuleCustomForm.get("name")?.value, this.newModuleCustomForm.get("description")?.value),
        )
        .subscribe((module) => {
          this.modeCreation = false;
          this.moduleCustomList.push(module);
          this.addRows(this.projects.length);
        });
    } else {
      if (!this.newModuleCustomForm.get("moduleKey")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "La clef du module doit comporter " + inputLength.minModuleCustomKey + " caractères minimum" });
      } else if (!this.newModuleCustomForm.get("name")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom du module doit comporter " + inputLength.minModuleCustomName + " caractères minimum" });
      }
    }
  }

  messageCheckbox(indexModule: number, indexProject: number) {
    if (this.moduleCustomList[indexModule].projects[indexProject] && this.moduleCustomList[indexModule].projects[indexProject].isModuleUsedInProject) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Action impossible", detail: "Vous ne pouvez pas désassocier ce module, il est utilisé dans ce projet" });
    }
  }
}
