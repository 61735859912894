<div class="card">
  <form [formGroup]="informationsForm">
    <div class="header">
      <div class="horizontal-display">
        <h2 title="Cette section sert à configurer le parcours">Configuration du parcours<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>
      </div>
    </div>

    <div class="horizontal-display">
      <div class="form-field">
        <div style="width: 100%">
          <label htmlFor="itineraryLabel">Label du parcours</label>
          <p>Choisissez un nom qui ne sera visible que par vous pour identifier le parcours</p>
          <div>
            <input #itineraryLabel pInputText id="itineraryLabel" formControlName="itineraryLabel" [maxlength]="inputLength.maxItineraryLabel" />
          </div>
          <small class="char-count"> ({{ countLabelCharacters() }}/{{ inputLength.maxItineraryLabel }}) </small>
        </div>

        <div class="margin-top">
          <label htmlFor="latitude">Localisation</label>
          <p>Choisissez si les étapes de ce parours seront géolocalisables</p>
          <p-toggleswitch formControlName="isLocated" title="Activer/désactiver la localisation" />
        </div>
      </div>

      <div class="form-field">
        <span class="">
          <div class="horizontal-display">
            <div>
              <label htmlFor="languages">Langue(s) du parcours</label>
              <p>Choisissez les langues qui seront disponibles sur la tablette de l'utilisateur</p>
            </div>
            <div>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-plus-circle-dotted action-buttons-item"
                title="Ajouter des langues"
                (click)="visibleLanguages = true"
                (keypress)="visibleLanguages = true">
              </i>
            </div>
          </div>

          @for (language of selectedLanguages; track $index) {
            <div class="languages-selected">
              <p class="added-language">{{ language.name }}</p>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash action-buttons-item"
                title="Supprimer la langue {{ language.name }}"
                (click)="showPopupLanguageDeletion(language.id); $event.stopPropagation()"
                (keypress)="showPopupLanguageDeletion(language.id); $event.stopPropagation()">
              </i>
            </div>
          }
          @if (selectedLanguages.length === 0) {
            <p class="added-language">Aucune langue n'est associée au parcours.</p>
          }
        </span>
        @if (isLocated() && !isCreation) {
          <div class="margin-top">
            <div class="map-image-text">
              Sélectionnez une image pour remplacer la carte
            </div>
            @if (mapImage !== undefined) {
              <div class="image-association grey-color">
                <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons"> </i>
                <p class="image-name truncate-two-lines" [title]="'Nom du média: ' + mapImage.media.name + mapImage.media.extension">{{ mapImage.media.name }}</p>
                <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Désassocier l'image" (click)="removeMapImage()"> </i>
              </div>
            } @else {
              <div class="image-association grey-color">
                <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Associer une image" (click)="addMapImage()"></i>
                <p class="image-name">Aucune image associée</p>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </form>
  @if (isLocated() && !isCreation) {
    @if (showMap) {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Masquer la carte" (click)="extendMap(false)" (keypress)="extendMap(false)">Masquer la carte</i>
      <div style="margin-bottom: 20px"></div>
      <app-point-of-interest-map
        [pointsOfInterest]="itinerary?.pointOfInterests"
        [isPageItinerary]="true"
        [mapImageUrl]="fileUrl"
        (changeCoordinates)="this.changeCoordinates.emit($event)">
      </app-point-of-interest-map>
    } @else {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Afficher la carte" (click)="extendMap(true)" (keypress)="extendMap(true)">Afficher la carte</i>
    }
  }
</div>

<app-add-language-popup
  [availableLanguages]="languagesList"
  [visible]="visibleLanguages"
  [selectedLanguages]="selectedLanguages"
  [languageIdToDelete]="languageIdToDelete"
  (confirmActionEmitter)="languagesChanged($event)">
</app-add-language-popup>

<app-media-association
  [visible]="visibleMediaPopup"
  [mediaList]="mediaList"
  [filteredMediaList]="mediaList"
  [mediaVisualisationFromPage]="mediaVisualisationFromPage"
  [mediaToDisplay]="mapImage"
  [fileUrl]="fileUrl"
  [fileBlob]="fileBlob"
  (closePopupEmitter)="closeMediaPopup()"
  (associatedMediaEmitter)="associateMedia($event)">
</app-media-association>
