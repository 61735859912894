import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "app/Services/Auth-Service/auth.service";

/*
 * The aim of this guard is to redirect the user to the right url when he's gonna type the domain in his browser (https://cms.ohrizon.com/)
 * There is three different results possible:
 * redirection to /projectName/home if the user is already connected and has a Client role
 * redirection to /projectName/administration/projects if the user is already connected and has a Administrator role
 * redirection to /login if the user isn't connected
 */
export const baseRouteRedirectGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isLoggedIn()) {
    if (authService.getUserType() === "Administrator") {
      router.navigate([`/${authService.getProjectName()}/administration/projects`]);
    } else {
      router.navigate([`/${authService.getProjectName()}/home`]);
    }
    return true;
  } else {
    router.navigateByUrl("/login");
    return false;
  }
};
