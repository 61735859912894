<div class="body">
  <div class="page vertical-display">
    <div class="header">
      <h1><i class="bi bi-eye" title="Prévisualisation du contenu"></i> Prévisualisation du contenu</h1>
      <p-select
        [options]="languagesSelected"
        [(ngModel)]="selectedLanguageId"
        [emptyMessage]="'Aucune langue disponible'"
        optionValue="id"
        optionLabel="name"
        placeholder="Choisir la langue"
        (onChange)="setLanguage()"/>
      <a routerLink="/{{ getProjectName() }}/{{ getObjectUrl() }}">
        <i tabindex="0" aria-hidden="true" class="bi bi-pencil action-buttons-item" title="Modifier le contenu"></i>
      </a>
    </div>

    <p class="disclaimer">Note: Cette version de la prévisualisation est une version préliminaire. Certains rendus peuvent différer de la version disponible sur votre tablette. Le chargement des images et des vidéos peut être long.</p>

    @switch (entityType) {
      @case ("itinerary") {
        <app-itinerary-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-itinerary-previsualisation>
      }
      @case ("pointOfInterest") {
        <app-point-of-interest-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-point-of-interest-previsualisation>
      }
      @case ("quiz") {
        <app-quiz-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-quiz-previsualisation>
      }
      @case ("gallery") {
        <app-gallery-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-gallery-previsualisation>
      }
      @case ("custom") {
        <app-custom-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-custom-previsualisation>
      }
      @case ("quizIncremental") {
        <app-quiz-inc-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-quiz-inc-previsualisation>
      }
      @case ('quizParticipatory') {
        <app-quiz-part-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          [entityId]="entityId"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languagesSelectedChanged)="languagesSelected = $event">
        </app-quiz-part-previsualisation>
      }
      @default {
        <app-itinerary-list-previsualisation
          [selectedLanguageId]="selectedLanguageId"
          [languageList]="languageList"
          (entityIdChanged)="entityId = $event"
          (entityTypeChanged)="entityType = $event"
          (languageChanged)="selectedLanguageId = $event">
        </app-itinerary-list-previsualisation>
      }
    }
  </div>
</div>
