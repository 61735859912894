import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { Dialog } from "primeng/dialog";

@Component({
  selector: 'app-icon-picker-popup',
  templateUrl: './icon-picker-popup.component.html',
  styleUrl: './icon-picker-popup.component.css',
  imports: [Dialog, PrimeTemplate, NgClass]
})
export class IconPickerPopupComponent implements OnChanges {

  @Input() visible: boolean = false;
  @Input() selectedIcon: string = "";
  @Output() sendIconNameEmitter: EventEmitter<string> = new EventEmitter<string>();

  previousIcon: string = "";

  icons: string[] = [
    'bi-alarm', 'bi-bell', 'bi-bookmark', 'bi-calendar', 'bi-camera', 'bi-cart',
    'bi-chat', 'bi-clock', 'bi-cloud', 'bi-heart', 'bi-house', 'bi-palette', 'bi-pen'
  ];

  ngOnChanges() {
    this.previousIcon = this.selectedIcon;
  }

  selectIcon(iconName: string) {
    this.selectedIcon = iconName;
  }

  closePopup(confirmAction: boolean) {
    if (confirmAction) {
      this.sendIconNameEmitter.emit(this.selectedIcon);
    } else {
      this.sendIconNameEmitter.emit(this.previousIcon);
    }
    this.selectedIcon = "";
  }

}
