import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { administrationType } from "app/Enumerations/administration-type.enum";
import { responseCode } from "app/Enumerations/response-code.enum";
import { Language } from "app/Models/language";
import { ModuleCustomAssociation, ModuleCustomTemplate } from "app/Models/module";
import { BuildPlatform, Project } from "app/Models/project";
import { User } from "app/Models/user";
import { DataService } from "app/Services/Data-Service/data.service";
import { MessageService } from "primeng/api";
import { InputText } from "primeng/inputtext";
import { MultiSelect } from "primeng/multiselect";
import { SelectModule } from "primeng/select";
import { ProgressSpinner } from "primeng/progressspinner";
import { NgClass } from "@angular/common";
import { ConfirmationPopupComponent } from "../../../Popups/confirmation-popup/confirmation-popup.component";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { Media } from "app/Models/media";
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from "@angular/router";
import { ProjectComponent } from "../project/project.component";
import { CustomModulesComponent } from "../custom-modules/custom-modules.component";

@Component({
  selector: "app-administation-page",
  templateUrl: "./administation-page.component.html",
  styleUrl: "./administation-page.component.css",
  imports: [FormsModule, InputText, ReactiveFormsModule, MultiSelect, SelectModule, ProgressSpinner, NgClass, CustomModulesComponent, ConfirmationPopupComponent, ProjectComponent, RouterLinkActive, RouterLink],
})
export class AdministationPageComponent implements OnInit {
  administrationType: number = 1;
  modeCreation = false;
  projects: Project[] = [];
  users: User[] = [];
  buildPlatforms: BuildPlatform[] = [];
  moduleCustomList: ModuleCustomTemplate[] = [];
  modifications: ModuleCustomAssociation[] = [];
  languages: Language[] = [];
  mediasUpdated: Media[] = [];
  noMediaUpdated: boolean = false;

  rolesList = ["Administrator", "Client"];

  indexUserToModify = -1;

  requestInProgress: boolean = false;
  sendConfirmationEmailClient: number = -1;

  //Forms
  userForm: FormGroup;
  projectName: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minProjectName)]);
  projectBuildPlatforms: FormControl = new FormControl("");
  userFirstName: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minUsername)]);
  userSurname: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minUsername)]);
  userRole: FormControl = new FormControl("");
  userProjects: FormControl = new FormControl("");

  //Enumerations
  enumAdministrationType = administrationType;

  //Confirmation popup
  visiblePopup: boolean = false;

  visibleConfirmActionPopup: boolean = false;
  confirmTypeAction: number = confirmDialogType.Delete;
  userEmail: string = "";
  indexUserDeletion: number = -1;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.userForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(inputLength.minUsername)]],
      surname: ["", [Validators.required, Validators.minLength(inputLength.minUsername)]],
      emailAddress: ["", [Validators.email]],
      role: ["", Validators.required],
      projectAssociated: ["", Validators.required],
    });
  }

  ngOnInit() {

    const routePath = this.route.snapshot.routeConfig?.path;

    if (routePath != null) {

      switch(routePath) {
        case `:projectName/administration/project/:id`:
          this.administrationType = this.enumAdministrationType.Project;
          break;
        case `:projectName/administration/projects`:
          this.administrationType = this.enumAdministrationType.Projects;
          this.dataService.getProjects().subscribe((projects) => {
            this.projects = projects;
          });
          this.dataService.getBuildPlatforms().subscribe((buildPlatforms) => {
            this.buildPlatforms = buildPlatforms;
          });
          break;
        case `:projectName/administration/users`:
          this.administrationType = this.enumAdministrationType.Users;
          this.dataService.GetUsersAndTheirProjects().subscribe((users) => {
            this.users = users;
          });
          this.dataService.getProjects().subscribe((projects) => {
            this.projects = projects;
          });
          break;
        case `:projectName/administration/custom-modules`:
          this.administrationType = this.enumAdministrationType.ModuleCustom;
          break;
        case `:projectName/administration/languages`:
          this.administrationType = this.enumAdministrationType.Languages;
          this.dataService.getLanguages().subscribe((languages) => {
            this.languages = languages;
          });
          break;
        case `:projectName/administration/debug`:
          this.administrationType = this.enumAdministrationType.Debug;
          break;
      }
    }

    this.setPageTitle();
  }

  createUser() {
    const formRaw = this.userForm.getRawValue();
    if (this.userForm.valid) {
      this.requestInProgress = true;
      const newUser = new User(0, formRaw.emailAddress, formRaw.firstName, formRaw.surname, formRaw.role, formRaw.projectAssociated);
      this.dataService.createUser(newUser).subscribe((response) => {
        this.requestInProgress = false;
        if (response.errorCode === responseCode.SuccessfulCreation) {
          this.userForm.reset();
          this.users.push(response.data);
          this.messageService.clear();
          this.messageService.add({ severity: "success", summary: "Utilisateur créé", detail: "La création de l'utilisateur a réussi" });
          this.modeCreation = false;
        } else {
          this.messageService.add({ severity: "error", summary: "Erreur", detail: response.errorMessage });
        }
      });
    } else {
      if (!this.userForm.get("emailAddress")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "L'adresse email est invalide" });
      } else if (!this.userForm.get("surname")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom de l'utilisateur doit comporter " + inputLength.minUsername + " caractères minimum" });
      } else if (!this.userForm.get("firstName")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le prénom de l'utilisateur doit comporter " + inputLength.minUsername + " caractères minimum" });
      } else if (!this.userForm.get("role")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "Veuillez choisir un role pour l'utilisateur" });
      } else if (!this.userForm.get("projectAssociated")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "Veuillez associer un projet à l'utilisateur" });
      }
    }
  }

  createProject() {
    if (this.isProjectComplete()) {
      const buildPlatforms: BuildPlatform[] = [];
      this.projectBuildPlatforms.value.forEach((buildPlatformId: number) => {
        buildPlatforms.push(this.buildPlatforms[this.buildPlatforms.findIndex((bp) => bp.id === buildPlatformId)]);
      });

      this.dataService.createProject(new Project(0, this.projectName.value, buildPlatforms)).subscribe((project) => {
        this.projectBuildPlatforms.reset();
        this.projects.push(project);
        this.messageService.clear();
        this.messageService.add({ severity: "success", summary: "Projet créé", detail: "La création du projet a réussi" });
        this.modeCreation = false;
      });
    }
  }

  modifyUser(indexUser: number) {
    this.indexUserToModify = indexUser;
    this.userFirstName.setValue(this.users[indexUser].firstName);
    this.userSurname.setValue(this.users[indexUser].surname);
    this.userRole.setValue(this.users[indexUser].role);
    this.userProjects.setValue(this.users[indexUser].projects.map((p) => p.id));
  }

  deleteUser(indexUser: number) {
    const currentUserEmail = this.authService.getUserEmail();
    if (currentUserEmail === this.users[indexUser].email) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Suppression impossible", detail: "Vous ne pouvez pas vous supprimer vous-même" });
    } else {
      this.indexUserDeletion = indexUser;
      this.userEmail = this.users[indexUser].email;
      this.visibleConfirmActionPopup = true;
    }
  }

  confirmDeleteUser(confirmAction: boolean) {
    if (confirmAction) {
      this.dataService.deleteUser(this.users[this.indexUserDeletion].id).subscribe((response) => {
        if (response.errorCode === responseCode.SuccessfulDeletion) {
          this.messageService.clear();
          this.messageService.add({ severity: "success", summary: "Suppression réussie", detail: this.userEmail + " a été supprimé" });
          this.users.splice(this.indexUserDeletion, 1);
        } else {
          this.messageService.clear();
          this.messageService.add({ severity: "error", summary: "Suppression échouée", detail: this.userEmail + " n'a pas pu être supprimé" });
        }
        this.closeAndResetConfirmationPopup();
      });
    } else {
      this.closeAndResetConfirmationPopup();
    }
  }

  closeAndResetConfirmationPopup() {
    this.indexUserDeletion = -1;
    this.userEmail = "";
    this.visibleConfirmActionPopup = false;
  }

  resetUserModification() {
    this.userRole.reset();
    this.userProjects.reset();
    this.userFirstName.reset();
    this.userSurname.reset();
    this.indexUserToModify = -1;
  }

  updateUser() {
    if (this.isUserComplete()) {
      const user = this.users[this.indexUserToModify];
      const userToUpdate: User = new User(user.id, user.email, this.userFirstName.value, this.userSurname.value, this.userRole.value, this.userProjects.value);
      this.dataService.updateUser(userToUpdate).subscribe((response) => {
        if (response.errorCode === responseCode.SuccessfulUpdate) {
          this.users[this.indexUserToModify].role = this.userRole.value;
          this.users[this.indexUserToModify].firstName = this.userFirstName.value;
          this.users[this.indexUserToModify].surname = this.userSurname.value;
          this.users[this.indexUserToModify].projects = [];
          for (let i = 0; i < this.userProjects.value.length; i++) {
            const projectIndex = this.projects.findIndex((p) => p.id == this.userProjects.value[i]);
            this.users[this.indexUserToModify].projects.push(this.projects[projectIndex]);
          }
          this.resetUserModification();
          this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "L'utilisateur a été mis à jour" });
        } else if (response.errorCode === responseCode.NotModified) {
          this.messageService.clear();
          this.messageService.add({ severity: "warn", summary: response.errorMessage, detail: response.data });
          this.resetUserModification();
        }
      });
    }
  }

  isProjectComplete(): boolean {
    if (!this.projectName.valid) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom du projet doit comporter " + inputLength.minProjectName + " caractères minimum" });
      return false;
    } else if (this.projectBuildPlatforms.value.length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le projet doit comporter au moins une plateforme de build" });
      return false;
    } else {
      return true;
    }
  }

  isUserComplete(): boolean {
    this.messageService.clear();
    if (this.userProjects.value.length === 0) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "L'utilisateur doit être associé à au moins un projet" });
    } else if (!this.userFirstName.valid) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le prénom de l'utilisateur doit comporter au moins " + inputLength.minUsername + " caractères" });
    } else if (!this.userSurname.valid) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom de l'utilisateur doit comporter au moins " + inputLength.minUsername + " caractères" });
    } else {
      return true;
    }
    return false;
  }

  setPageTitle() {
    document.title = "Administration";
  }

  sendConfirmAndSetPasswordEmail(indexUser: number, email: string) {
    this.sendConfirmationEmailClient = indexUser;
    this.dataService.sendConfirmAndSetPasswordEmail(email).subscribe((response) => {
      if (response.errorCode === responseCode.SuccessfulCreation) {
        this.sendConfirmationEmailClient = -1;
        this.messageService.add({ severity: "success", summary: "Email envoyé", detail: "L'utilisateur a reçu un nouveau mail de confirmation de compte" });
      }
    });
  }

  displayBoolean(bool: boolean) {
    if (bool) {
      return "Oui";
    } else {
      return "Non";
    }
  }

  generateMissingThumbnails(onlyGenerateMissingThumbnails: boolean) {
    this.requestInProgress = true;
    this.dataService.generateMissingThumbnailsOnly(onlyGenerateMissingThumbnails).subscribe((response) => {
      this.requestInProgress = false;
      this.mediasUpdated = response;
      this.noMediaUpdated = this.mediasUpdated.length == 0;
    })
  }

  navigateProject(projectId: number) {
    this.router.navigateByUrl(`${this.authService.getProjectName()}/administration/project/${projectId}`);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
