<div class="POI-card">
  <div class="horizontal-display">
    <div class="draggable-zone" (mousedown)="dragStarted($event)">
      <i class="icon-drag-drop bi bi-grip-horizontal" title="Glisser et déposer pour réorganiser l'ordre des étapes"></i>
    </div>

    <div>
      <h3 [title]="'Label de l\'étape : ' + pointOfInterest.identificationName" class="truncate-one-line">
        <a routerLink="/{{ this.getProjectName() }}/point-of-interest-management/{{ this.pointOfInterest.id }}">{{ pointOfInterest.identificationName }}</a>
      </h3>
      @if (pointOfInterest) {
        <app-publication-state [publicationState]="pointOfInterest.state" [entityType]="enumEntityType.PointOfInterest"> </app-publication-state>
        <app-completion-state class="margin-left" [completionState]="pointOfInterest.readyForPublication" [tooltipTrue]="'L\'étape est complète'" [tooltipFalse]="'L\'étape n\'est pas complète'">
        </app-completion-state>
      }
    </div>
  </div>

  <div class="right-section">
    <div class="infos-POI">
      <div class="infos-POI-item" title="Nombre d'activités associées à l'étape">
        <p>{{ pointOfInterest.countOfModules }}</p>
        <img class="icon" src="assets/icons/Activite_2X.png" alt="icon"/>
      </div>
    </div>

    <div class="action-buttons">
      <a routerLink="/{{ this.getProjectName() }}/point-of-interest-management/{{ this.pointOfInterest.id }}" title="Modifier" class="action-buttons-item">
        <i class="bi bi-pencil"></i>
      </a>
      <!-- TODO: remove aria-hidden -->
      <i
        tabIndex="0"
        aria-hidden="true"
        class="bi bi-trash action-buttons-item"
        title="Supprimer"
        (click)="deletePointOfInterest(); $event.stopPropagation()"
        (keypress)="deletePointOfInterest(); $event.stopPropagation()">
      </i>
    </div>
  </div>
</div>
