<div id="map" [ngClass]="{ 'pointer-cursor': selectedPOILocation }">
  @if (isPageItinerary) {
    <div id="poi-list-container" class="poi-list-container">
      @for (pointOfInterest of pointsOfInterest; track $index) {
        <div class="poi-list-element" tabindex="0" aria-hidden="true" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
          <span class="poi-name" title="{{ pointOfInterest.identificationName }}">{{ pointOfInterest.identificationName }}</span>
          <div class="button-group">
            @if (selectedPOI?.id === pointOfInterest.id) {
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-x-lg action-buttons-item map-buttons"
                title="Sélectionner ce marqueur"
                (click)="selectPOI($event, pointOfInterest)"
                (keypress)="selectPOI($event, pointOfInterest)">
              </i>
            } @else {
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-fullscreen action-buttons-item map-buttons"
                title="Sélectionner ce marqueur"
                (click)="selectPOI($event, pointOfInterest)"
                (keypress)="selectPOI($event, pointOfInterest)"
                [ngClass]="{ 'disabled-icon': !markers.has(pointOfInterest.id) }">
              </i>
            }
            @if (selectedPOILocation?.id === pointOfInterest.id) {
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-x-lg action-buttons-item map-buttons highlighted-button"
                title="Annuler"
                (click)="setLocationStartFromList($event, pointOfInterest)"
                (keypress)="setLocationStartFromList($event, pointOfInterest)">
              </i>
            } @else {
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-geo-alt action-buttons-item map-buttons"
                title="Définir la localisation"
                (click)="setLocationStartFromList($event, pointOfInterest)"
                (keypress)="setLocationStartFromList($event, pointOfInterest)">
              </i>
            }
            <i
              tabIndex="0"
              aria-hidden="true"
              class="bi bi-trash action-buttons-item map-buttons"
              title="Supprimer le marqueur"
              (click)="removeMarker(pointOfInterest)"
              (keypress)="removeMarker(pointOfInterest)"
              [ngClass]="{ 'disabled-icon': !markers.has(pointOfInterest.id) }">
            </i>
          </div>
        </div>
      }
    </div>
  } @else {
    @if (isSetLocationSelected) {
      <i
        tabIndex="0"
        aria-hidden="true"
        class="bi bi-x-lg action-buttons-item localisation-button map-button highlighted-button"
        title="Annuler"
        (click)="setLocationStart($event, false)"
        (keypress)="setLocationStart($event, false)"
        (mousedown)="$event.stopPropagation()">
      </i>
    } @else {
      <i
        tabIndex="0"
        aria-hidden="true"
        class="bi bi-geo-alt action-buttons-item localisation-button map-button"
        title="Définir la localisation"
        (click)="setLocationStart($event, true)"
        (keypress)="setLocationStart($event, true)"
        (mousedown)="$event.stopPropagation()">
      </i>
    }
    <i
      tabIndex="0"
      aria-hidden="true"
      class="bi bi-trash action-buttons-item delete-button map-button"
      title="Supprimer le marqueur"
      (click)="$event.stopPropagation(); removeMarkerDetail()"
      (keypress)="removeMarkerDetail()"
      [ngClass]="{ 'disabled-icon': (!this.pointOfInterest && !markers.has(0)) || (this.pointOfInterest && !markers.has(this.pointOfInterest.id)) }">
    </i>
  }
  @if (!mapImageUrl) {
    <i
      tabIndex="0"
      aria-hidden="true"
      class="bi bi-fullscreen action-buttons-item center-button map-button"
      title="Centrer la carte sur les marqueurs"
      (click)="$event.stopPropagation(); centerMapOnMarkers()"
      (keypress)="centerMapOnMarkers()"
      [ngClass]="{ 'disabled-icon': markers.size === 0 }">
    </i>
  }
</div>

<app-confirmation-popup [visible]="visible" [confirmTypeAction]="enumDialogType.Delete" [entityTypeAction]="enumEntityType.Marker" (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
