@if (module) {
  <div class="previsualisation-container">
    @if (synthesisVisualisation) {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()"/>
    } @else if (module.pointOfInterestId) {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()"/>
    }    <div class="title-container">
      @if (displayModuleName()) {
        <span class="title">{{displayModuleName()}}</span>
      } @else {
        <span class="title placeholder">Nom de l'activité</span>
      }
    </div>
    <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>

    @if (!synthesisVisualisation) {
      @if (!displayQuestion()) {
        <p class="placeholder question">Intitulé de la question</p>
      } @else {
        <p class="question" [ngClass]="{'question-small-text': displayQuestion() && displayQuestion()!.length >= 170}">{{displayQuestion()}}</p>
      }

      @if (module.questions[0].categories!.length > 1) {
        <div class="category-list">
          @for (category of module.questions[0].categories; track $index) {
            <div class="category" tabindex="0" aria-hidden="true" (click)="selectCategory($index)" (keypress)="selectCategory($index)">
              <div [ngClass]="{'category-top' : $index === 0 , 'category-bottom' : $index === module.questions[0].categories!.length - 1 ,  'category-middle' : $index !== 0 && $index !== module.questions[0].categories!.length - 1}"></div>
              <i [class]="'bi category-icon ' + category.icon" [ngClass]="{'icon-top' : $index === 0 , 'icon-bottom' : $index === module.questions[0].categories!.length - 1, 'selected-category' : $index === currentCategoryIndex}"></i>
            </div>
          }
        </div>
      }

      <div class="category-container" [style]="getColor()" [ngClass]="{'category-container-offset' : module.questions[0].categories!.length > 1}">
        <div class="category-name" [ngClass]="{'category-name-small-text' : displayCategory() && displayCategory()!.length >= 25}">
          @if (displayCategory()) {
            {{displayCategory()}}
          } @else {
            <span class="placeholder">Nom de la catégorie</span>
          }
        </div>
        <div class="answer-list">
          @for (answer of currentCategory?.answers; track $index) {
            <div tabindex="0" aria-hidden="true" class="answer centered" [ngClass]="{'dark-answer' : $index % 2 === 0, 'selected-answer' : selectedAnswerList[currentCategoryIndex][$index]}" (click)="selectAnswer($index)">
              @if (displayAnswer(answer)) {
                <span class="answer-text">{{displayAnswer(answer)}}</span>
              } @else {
                <span class="placeholder">Réponse</span>
              }
              @if (selectedAnswerList[currentCategoryIndex][$index]) {
                <img class="good-answer-icon" src="assets/images/Good_Answer_Icon.png" alt="image"/>
              }
            </div>
          }
          @for (i of maxAnswers; track $index) {
            @if (currentCategory?.answers?.length! <= $index) {
              <div class="answer" style="cursor: default;" [ngClass]="{'dark-answer' : $index % 2 === 0}"></div>
            }
          }
        </div>
        <div class="fake-scrollbar">
          @for (answer of currentCategory?.answers; track $index) {
            <div style="height: 40px;"></div>
          }
        </div>
      </div>

      <img class="ipad" src="assets/images/Ipad_Empty.png" alt="image"/>
      @if (module.timer && module.timer > 0) {
        <div class="timer">{{displayTimer()}}</div>
      }
      <div class="ipad ipad-container">
          @for (category of module.questions[0].categories; track $index) {
            <div class="ipad-category" [style]="'background-color: ' + module.backgroundColor + ';'">
              <i class="bi bi-circle-fill ipad-category-icon" [style]="'font-size: ' + getIconSize($index).toString() + 'px; color: ' + category.color + ';'"></i>
              @if (category.icon) {
                <i [class]="'bi ipad-category-icon ' + category.icon" [style]="'font-size: ' + (getIconSize($index) * 0.7).toString() + 'px;'"></i>
              } @else {
                <i class="bi bi-card-image placeholder ipad-category-icon" [style]="'font-size: ' + (getIconSize($index) * 0.7).toString() + 'px;'"></i>
              }
            </div>
          }
      </div>

      <div tabindex="0" aria-hidden="true" style="cursor: pointer;" (click)="incrementCategory(true)" (keypress)="incrementCategory(true)">
        <img class="other-answer" src="assets/images/Other_Answer_Button_Icon.png" alt="image"/>
        <span class="other-answer-text">Autre bonne réponse</span>
      </div>
      <div tabindex="0" aria-hidden="true" style="cursor: pointer;" (click)="incrementCategory(false)" (keypress)="incrementCategory(false)">
        <img class="bad-answer" src="assets/images/Bad_Answer_Button_Icon.png" alt="image"/>
        <span class="bad-answer-text">Mauvaise réponse</span>
      </div>
      @if (synthesisFileUrl !== '' || module!.moduleTranslations[0].synthesis !== '') {
        <img tabindex="0" aria-hidden="true" src="assets/images/Synthese_Button.png" alt="image" class="synthesis-icon" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()"/>
      }
    } @else {
      <div class="synthesis-container vertical-display scrollbar">
        <p style="text-align: center;"> {{displaySynthesis()}} </p>
        @if (module.mediaSynthesis) {
          @if (synthesisFileUrl !== '') {
            <img style="max-height: 100%; max-width: 100%;" [src]="synthesisFileUrl" alt="image"/>
          } @else {
            <p-progressSpinner></p-progressSpinner>
          }
        }
      </div>
    }

    <app-information-popup
      [visible]="isInformationPopupVisible"
      [type]="module.type"
      (closePopupEmitter)="isInformationPopupVisible = false">
    </app-information-popup>
  </div>
}
