import { Component, AfterViewInit, Input, SimpleChanges, EventEmitter, Output, OnChanges, OnDestroy } from "@angular/core";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { PointOfInterest } from "app/Models/point-of-interest";
import { PointOfInterestCoordinates } from "app/Models/pointOfInterestCoordinates";
import { PointOfInterestDetail } from "app/Models/PointOfInterestDetail";
import * as L from "leaflet";
import { MessageService } from "primeng/api";
import { NgClass } from "@angular/common";
import { ConfirmationPopupComponent } from "../../Popups/confirmation-popup/confirmation-popup.component";

@Component({
  selector: "app-point-of-interest-map",
  templateUrl: "./point-of-interest-map.component.html",
  styleUrls: ["./point-of-interest-map.component.css"],
  imports: [NgClass, ConfirmationPopupComponent],
})
export class PointOfInterestMapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() isPageItinerary?: boolean;
  @Input() pointsOfInterest?: PointOfInterest[] = [];
  @Input() pointOfInterest?: PointOfInterestDetail;
  @Input() pointsOfInterestCoordinates?: PointOfInterestCoordinates[] = [];
  @Input() formLatitude?: number;
  @Input() formLongitude?: number;
  @Input() mapImageUrl?: string;

  @Output() changeCoordinates: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();
  @Output() changeCoordinatesDetail: EventEmitter<PointOfInterestDetail> = new EventEmitter<PointOfInterestDetail>();
  @Output() changeFormCoordinates: EventEmitter<{ latitude: number; longitude: number }> = new EventEmitter<{ latitude: number; longitude: number }>();

  isSetLocationSelected: boolean = false;
  visible: boolean = false;
  selectedPOILocation?: PointOfInterest;
  selectedPOI?: PointOfInterest;
  selectedPOIToDelete?: PointOfInterest;

  map?: L.Map;
  markers: Map<number, L.Marker> = new Map();

  //Enumerations
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;

  iconDefault: L.Icon = L.icon({
    iconUrl: "assets/images/marker-icon.png",
    shadowUrl: "assets/images/marker-shadow.png",
    iconRetinaUrl: "assets/images/marker-icon-2x.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  iconSelected: L.Icon = L.icon({
    iconUrl: "assets/images/marker-icon-orange.png",
    shadowUrl: "assets/images/marker-shadow.png",
    iconRetinaUrl: "assets/images/marker-icon-orange-2x.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  constructor(private messageService: MessageService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.clearMap();
      this.initMap();
      this.addPointsToMap();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["pointsOfInterest"] || changes["pointOfInterest"] || changes["pointsOfInterestCoordinates"]) {
      this.clearMap();
      this.addPointsToMap();
    }
    if (changes["formLatitude"] || changes["formLongitude"]) {
      this.changeNewPoiCoordinatesFromForm();
      this.centerMapOnMarkers();
    }
    if (changes["mapImageUrl"]) {
      this.initMap();
      if (this.pointsOfInterest) {
        this.pointsOfInterest.forEach(element => {
          this.removePOICoordinates(element);
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
      this.map = undefined;
    }
  }

  initMap() {
    if (this.map) {
      this.map.remove();
    }
    if (this.mapImageUrl) {
      this.map = L.map('map', {
        crs: L.CRS.Simple,
        zoomControl: false,
        attributionControl: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        touchZoom: false,
        dragging: false
      })
      const mapContainer = document.getElementById('map');
      const container = document.getElementById('container');
        const img = new Image();
        img.src = this.mapImageUrl;
        img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            if (container && mapContainer) {
              const containerWidth = container.getBoundingClientRect().width;
              const adjustedHeight = (imgHeight / imgWidth) * containerWidth;
              mapContainer.style.height = `${adjustedHeight}px`;
              const bounds = L.latLngBounds([0, 0], [adjustedHeight, containerWidth]);
              L.imageOverlay(this.mapImageUrl!, bounds).addTo(this.map!);
              this.map!.fitBounds(bounds);
            }
        }
    } else {
      this.map = L.map("map", {
        center: [48.8566, 2.3522],
        zoom: 13,
        doubleClickZoom: false,
      });
      const mapContainer = document.getElementById('map');
      if (mapContainer) {
        mapContainer.style.height = `600px`;
      }
      L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
        maxZoom: 19,
      }).addTo(this.map);
    }

    this.map.on("click", (event: L.LeafletMouseEvent) => {
      this.resetMarkers();
      this.selectedPOI = undefined;

      const lat = event.latlng.lat;
      const lng = event.latlng.lng;

      if (this.selectedPOILocation && this.map) {
        this.addMarkerToMap(this.selectedPOILocation.id, this.selectedPOILocation.identificationName, lat, lng);

        this.selectedPOILocation.latitude = lat;
        this.selectedPOILocation.longitude = lng;
        this.changeCoordinates.emit(this.selectedPOILocation);
        this.selectedPOILocation = undefined;
      }

      if (this.isSetLocationSelected && this.map && this.pointOfInterest) {
        this.addMarkerToMap(this.pointOfInterest.id, this.pointOfInterest.identificationName, lat, lng);

        this.pointOfInterest.latitude = lat;
        this.pointOfInterest.longitude = lng;
        this.changeCoordinatesDetail.emit(this.pointOfInterest);
      }

      if (this.isSetLocationSelected && this.map && !this.pointOfInterest) {
        this.addMarkerToMap(0, "Nouvelle étape", lat, lng);

        this.formLatitude = lat;
        this.formLongitude = lng;
        this.changeFormCoordinates.emit({ latitude: lat, longitude: lng });
      }

      this.isSetLocationSelected = false;
    });
    const listContainer = document.getElementById("poi-list-container");
    if (listContainer) {
      L.DomEvent.disableScrollPropagation(listContainer);
    }
  }

  addPointsToMap() {
    if (this.pointsOfInterest?.length && this.map) {
      this.pointsOfInterest.forEach((point) => {
        if (point.latitude && point.longitude && this.map) {
          this.addMarkerToMap(point.id, point.identificationName, point.latitude, point.longitude);
        }
      });
    }

    if (this.pointOfInterest && this.map) {
      if (this.pointOfInterest.latitude && this.pointOfInterest.longitude && this.map) {
        this.addMarkerToMap(this.pointOfInterest.id, this.pointOfInterest.identificationName, this.pointOfInterest.latitude, this.pointOfInterest.longitude);
      }
    }

    if (this.pointsOfInterestCoordinates?.length && this.map) {
      this.pointsOfInterestCoordinates.forEach((point) => {
        if (point.latitude && point.longitude && this.map && point.id != this.pointOfInterest?.id) {
          this.addMarkerToMap(point.id, point.identificationName, point.latitude, point.longitude, 0.4);
        }
      });
    }


    this.centerMapOnMarkers();
    if (!this.mapImageUrl) {
      if (this.markers.size === 1) {
        this.map?.setZoom(14);
      }
      if (this.markers.size === 0) {
        this.map?.setZoom(2);
      }
    }
  }

  addMarkerToMap(id: number, name: string, latitude: number, longitude: number, opacity?: number) {
    const roundedLat = latitude.toFixed(5);
    const roundedLng = longitude.toFixed(5);
    if (this.markers.has(id)) {
      const existingMarker = this.markers.get(id);
      existingMarker?.setLatLng([latitude, longitude]);
      existingMarker?.getPopup()?.setContent(`<b>${name}</b><br>Coordonnées: ${roundedLat}, ${roundedLng}`);
    } else if (this.map) {
      const marker = L.marker([latitude, longitude], { icon: this.iconDefault }).addTo(this.map);
      marker.bindPopup(`<b>${name}</b><br>Coordonnées: ${roundedLat}, ${roundedLng}`);
      this.markers.set(id, marker);
      if (opacity) {
        marker.setOpacity(opacity);
      }
    }
  }

  changeNewPoiCoordinatesFromForm() {
    if (this.formLatitude && this.formLongitude && this.map) {
      const roundedLat = this.formLongitude.toFixed(5);
      const roundedLng = this.formLongitude.toFixed(5);
      if (this.markers.has(0)) {
        const existingMarker = this.markers.get(0);
        existingMarker?.setLatLng([this.formLatitude, this.formLongitude]);
        existingMarker?.getPopup()?.setContent(`<b>${"Nouvelle étape"}</b><br>Coordonnées: ${roundedLat}, ${roundedLng}`);
      } else {
        const marker = L.marker([this.formLatitude, this.formLongitude]).addTo(this.map);
        marker.bindPopup(`<b>${"Nouvelle étape"}</b><br>Coordonnées: ${roundedLat}, ${roundedLng}`);
        this.markers.set(0, marker);
      }
    }
  }

  setLocationStart(event: Event, state: boolean) {
    event.stopPropagation();
    this.isSetLocationSelected = state;
    if (state) {
      this.messageService.add({ severity: "success", detail: "Cliquez sur la carte pour placer l'étape" });
      if (this.pointOfInterest) {
        this.highlightMarker(this.pointOfInterest.id);
      } else {
        this.highlightMarker(0);
      }
    } else {
      this.resetMarkers();
    }
  }

  setLocationStartFromList(event: Event, pointOfInterest: PointOfInterest) {
    event.stopPropagation();
    this.selectedPOI = undefined;
    if (this.selectedPOILocation?.id === pointOfInterest.id) {
      this.selectedPOILocation = undefined;
      this.resetMarkers();
    } else {
      this.resetMarkers();
      this.selectedPOILocation = pointOfInterest;
      if (this.selectedPOILocation) {
        this.highlightMarker(pointOfInterest.id);
        this.messageService.add({ severity: "success", summary: this.selectedPOILocation.identificationName + " sélectionnée", detail: "Cliquez sur la carte pour placer l'étape" });
      }
    }
  }

  selectPOI(event: Event, pointOfInterest: PointOfInterest) {
    event.stopPropagation();
    const marker = this.markers.get(pointOfInterest.id);
    if (this.selectedPOI != pointOfInterest && marker) {
      this.selectedPOI = pointOfInterest;
      marker?.openPopup();
      this.centerMapOnMarker(marker);
    } else {
      this.selectedPOI = undefined;
      marker?.closePopup();
    }
  }

  removeMarker(pointOfInterest: PointOfInterest) {
    if (this.markers.has(pointOfInterest.id)) {
      this.selectedPOIToDelete = pointOfInterest;
      this.visible = true;
    }
  }

  removeMarkerDetail() {
    if (this.pointOfInterest) {
      if (this.markers.has(this.pointOfInterest.id)) {
        this.visible = true;
      }
    }
    if (this.markers.has(0)) {
      this.visible = true;
    }
  }

  highlightMarker(markerId: number) {
    const marker = this.markers.get(markerId);
    this.markers.forEach((element) => {
      if (element === marker) {
        element.setIcon(this.iconSelected);
      }
    });
  }

  resetMarkers() {
    this.markers.forEach((marker) => {
      marker.setIcon(this.iconDefault);
    });
  }

  centerMapOnMarkers() {
    if (!this.mapImageUrl) {
      const bounds = L.latLngBounds([]);

      this.markers.forEach((marker) => {
        bounds.extend(marker.getLatLng());
      });

      if (bounds.isValid() && this.map && !this.isPageItinerary) {
        this.map.fitBounds(bounds, {
          padding: [50, 50],
        });
      }

      if (bounds.isValid() && this.map && this.isPageItinerary) {
        this.map.fitBounds(bounds, {
          padding: [250, 50],
        });
      }
    }
  }

  centerMapOnMarker(marker: L.Marker) {
    if (this.map && !this.mapImageUrl) {
      this.map.setView(marker.getLatLng());
    }
  }

  clearMap() {
    if (this.map) {
      this.markers.forEach((marker) => {
        this.map?.removeLayer(marker);
      });
      this.markers.clear();
    }
  }

  confirmActionDialog(confirmAction: boolean) {
    this.visible = false;
    let markerToDelete: L.Marker | undefined;
    if (confirmAction == true && this.map) {
      if (this.selectedPOIToDelete) {
        this.removePOICoordinates(this.selectedPOIToDelete);
        this.selectedPOIToDelete = undefined;
      } else if (this.pointOfInterest) {
        this.removePOIDetailCoordinates(this.pointOfInterest);
      } else if (this.markers.has(0)) {
        markerToDelete = this.markers.get(0);
        if (markerToDelete) {
          this.map.removeLayer(markerToDelete);
          this.markers.delete(0);
        }
      }
    }
  }

  removePOICoordinates(pointOfInterest: PointOfInterest) {
    const markerToDelete = this.markers.get(pointOfInterest.id);
        if (markerToDelete && this.map) {
          this.map.removeLayer(markerToDelete);
          this.markers.delete(pointOfInterest.id);
          pointOfInterest.latitude = undefined;
          pointOfInterest.longitude = undefined;
          this.changeCoordinates.emit(pointOfInterest);
        }
  }

  removePOIDetailCoordinates(pointOfInterest: PointOfInterestDetail) {
    const markerToDelete = this.markers.get(pointOfInterest.id);
    if (markerToDelete && this.map) {
      this.map.removeLayer(markerToDelete);
      this.markers.delete(pointOfInterest.id);
      pointOfInterest.latitude = undefined;
      pointOfInterest.longitude = undefined;
      this.changeCoordinatesDetail.emit(pointOfInterest);
    }
  }
}
