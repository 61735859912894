<div class="card">
  <form [formGroup]="configurationForm">
    <h2 title="Cette section sert à configurer l'étape">Configuration de l'étape<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>

    <div class="horizontal-display">
      <div class="vertical-display">
        <!-- <div> -->
        <div class="form-field">
          <span class="">
            <label htmlFor="pointOfInterestLabel">Label de l'étape</label>
            <p>Choisissez un nom qui ne sera visible que par vous pour identifier l'étape</p>
            <input pInputText id="pointOfInterestLabel" formControlName="pointOfInterestLabel" [maxlength]="inputLength.maxPointOfInterestLabel" />
            <small class="char-count"> ({{ countLabelCharacters() }}/{{ inputLength.maxPointOfInterestLabel }}) </small>
          </span>
        </div>
        @if (isLocated()) {
          <div class="form-field margin-top">
            <label htmlFor="latitude">Localisation</label>
            <div class="vertical-display">
              <p>Latitude</p>
              <input pInputText id="latitude" formControlName="latitude" type="number" (change)="onLatitudeChange($event)" (keydown.enter)="onLatitudeChange($event)" />
              <p>Longitude</p>
              <input pInputText id="longitude" formControlName="longitude" type="number" (change)="onLongitudeChange($event)" (keydown.enter)="onLongitudeChange($event)" />
            </div>
          </div>
        }
      </div>

      <div class="vertical-display">
        <!-- <p>Image de l'étape, à venir prochainement.</p> -->
        <p> </p>
      </div>
    </div>
  </form>
  @if (isLocated()) {
    @if (showMap) {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier la carte" (click)="extendMap(false)" (keypress)="extendMap(false)">Cacher la carte</i>
      <div style="margin-bottom: 20px"></div>
      <app-point-of-interest-map
        [isPageItinerary]="false"
        [pointOfInterest]="pointOfInterest"
        [pointsOfInterestCoordinates]="pointsOfInterestCoordinates"
        [formLatitude]="formLatitude"
        [formLongitude]="formLongitude"
        [mapImageUrl]="fileUrl"
        (changeCoordinatesDetail)="changeCoordinatesDetail.emit($event)"
        (changeFormCoordinates)="changeFormCoordinates($event)">
      </app-point-of-interest-map>
    } @else {
      <div style="margin-top: 20px"></div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier la carte" (click)="extendMap(true)" (keypress)="extendMap(true)">Afficher la carte</i>
    }
  }
</div>
