import { Component, OnInit } from "@angular/core";
import { Itinerary } from "app/Models/itinerary";
import { Language } from "app/Models/language";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { ItineraryPrevisualisationComponent } from "../itinerary-previsualisation/itinerary-previsualisation.component";
import { PointOfInterestPrevisualisationComponent } from "../point-of-interest-previsualisation/point-of-interest-previsualisation.component";
import { QuizPrevisualisationComponent } from "../modules/quiz-previsualisation/quiz-previsualisation.component";
import { GalleryPrevisualisationComponent } from "../modules/gallery-previsualisation/gallery-previsualisation.component";
import { CustomPrevisualisationComponent } from "../modules/custom-previsualisation/custom-previsualisation.component";
import { QuizIncPrevisualisationComponent } from "../modules/quiz-inc-previsualisation/quiz-inc-previsualisation.component";
import { ItineraryListPrevisualisationComponent } from "../itinerary-list-previsualisation/itinerary-list-previsualisation.component";
import { QuizPartPrevisualisationComponent } from "../modules/quiz-part-previsualisation/quiz-part-previsualisation.component";
import { Select } from "primeng/select";

@Component({
  selector: "app-previsualisation-page",
  templateUrl: "./previsualisation-page.component.html",
  styleUrl: "./previsualisation-page.component.css",
  imports: [
    DropdownModule,
    FormsModule,
    RouterLink,
    Select,
    ItineraryPrevisualisationComponent,
    PointOfInterestPrevisualisationComponent,
    QuizPrevisualisationComponent,
    GalleryPrevisualisationComponent,
    CustomPrevisualisationComponent,
    QuizIncPrevisualisationComponent,
    ItineraryListPrevisualisationComponent,
    QuizPartPrevisualisationComponent
  ],
})
export class PrevisualisationPageComponent implements OnInit {
  languageList: Language[] = [];
  languagesSelected: Language[] = [];
  selectedLanguageId: number = 1;
  visualisationModeList: string[] = ["Tablette" /* ,'Téléphone' */];
  selectedVisualisationMode = "Tablette";
  itineraryList: Itinerary[] = [];
  entityType?: string;
  entityId?: number;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.dataService.getLanguages().subscribe((languages) => {
      this.languageList = languages;
      this.languagesSelected = languages;
    });
    const storedLanguageId = localStorage.getItem("selectedLanguageForVisualisation");
    if (storedLanguageId) {
      this.selectedLanguageId = parseInt(storedLanguageId);
    } else {
      this.selectedLanguageId = this.languageList[0].id;
    }
    const storedVisualisationMode = localStorage.getItem("visualisationMode");
    if (storedVisualisationMode) {
      this.selectedVisualisationMode = storedVisualisationMode;
    }
    const storedEntityType = localStorage.getItem("entityType");
    if (storedEntityType) {
      this.entityType = storedEntityType;
      localStorage.setItem("entityType", "");
    }
    const storedEntityId = localStorage.getItem("entityId");
    if (storedEntityId) {
      this.entityId = parseInt(storedEntityId);
    }
    this.setPageTitle();
  }

  getObjectUrl(): string {
    switch (this.entityType) {
      case "itinerary":
        return "itinerary-management/" + this.entityId?.toString();

      case "pointOfInterest":
        return "point-of-interest-management/" + this.entityId?.toString();

      case "quiz":
      case "gallery":
      case "custom":
      case "quizIncremental":
      case "quizParticipatory":
        return "module-management/" + this.entityId?.toString();

      default:
        return "area-management";
    }
  }

  setLanguage() {
    localStorage.setItem("selectedLanguageForVisualisation", this.selectedLanguageId.toString());
  }

  setVisualisationMode() {
    localStorage.setItem("visualisationMode", this.selectedVisualisationMode.toString());
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Prévisualisation";
  }
}
