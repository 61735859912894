<div class="body" id="container">
  <div class="page">
    <div class="header">
      <div>
        <div class="itinerary-name-state">
          <h1 class="truncate-one-line">{{ itinerary?.identificationName || "Nouveau parcours" }}</h1>
          @if (itinerary) {
            <app-publication-state class="publication-state-margin single-line" [publicationState]="itinerary.state" [entityType]="enumEntityType.Itinerary"> </app-publication-state>
            @if (itinerary.state === enumPublishState.PublicationInProgress) {
              <div>
                <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
              </div>
            }
            <app-completion-state class="margin-left" [completionState]="itinerary.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'">
            </app-completion-state>
          }
        </div>

        @if (itinerary?.publicationDate) {
          <p>Dernière publication: {{ itinerary?.publicationDate | date: "yyyy-MM-dd' 'HH:mm:ss" }}</p>
        }
        @if (itinerary?.lastModificationDate) {
          <p>Dernière sauvegarde: {{ itinerary?.lastModificationDate | date: "yyyy-MM-dd' 'HH:mm:ss" }}</p>
        }
      </div>
      <div>
        @if (itinerary) {
          <a routerLink="/{{ this.getProjectName() }}/previsualisation">
            <i tabindex="0" aria-hidden="true" class="bi bi-eye action-buttons-item previsualisation-button" title="Prévisualiser le contenu" (click)="storeEntity()"></i>
          </a>
        }
        @if (isCreation) {
          <button class="button" (click)="createItinerary()" title="Sauvegarder le parcours">Sauvegarder</button>
        } @else {
          <p-splitButton label="Sauvegarder" title="Sauvegarder le parcours" (onClick)="updateItinerary()" [model]="menuItems"></p-splitButton>
        }
      </div>
    </div>

    <app-itinerary-configuration
      [informationsForm]="informationsForm"
      [selectedLanguages]="languagesSelected"
      [languagesList]="languagesList"
      [itinerary]="itinerary"
      [isCreation]="isCreation"
      (languageChangedEmitter)="configLanguageChanged($event)"
      (changeItineraryImage)="changeItineraryImage($event)">
    </app-itinerary-configuration>

    <app-itinerary-multilanguage-content
      [multiLanguageForm]="multiLanguageForm"
      [itineraryTranslation]="itineraryTranslation"
      [languagesSelected]="languagesSelected"
      (languageChangedEmitter)="languageChanged($event)"
      (saveLanguageEmitter)="saveLanguage()">
    </app-itinerary-multilanguage-content>

    <app-point-of-interest-list
      [pointOfInterestList]="itinerary?.pointOfInterests"
      [itineraryId]="itinerary?.id"
      [isCreation]="isCreation"
      (deletePointOfInterestEmitter)="showDialog(enumDialogType.Delete, enumEntityType.PointOfInterest, $event.id)"
      (navigateNewPointOfInterest)="navigateNewPointOfInterest()">
    </app-point-of-interest-list>
  </div>
</div>

<app-confirmation-popup
  [visible]="visible"
  [confirmTypeAction]="confirmTypeAction"
  [entityTypeAction]="entityTypeAction"
  [confirmationLabel]="itinerary?.identificationName"
  (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>

<app-publication-popup [publication]="lastPublication" [visible]="visiblePublication" (closePopupEmitter)="closePublicationPopup()"> </app-publication-popup>
