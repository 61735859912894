import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectDetails } from 'app/Models/projectDetails';
import { DataService } from 'app/Services/Data-Service/data.service';
import { ProgressSpinner } from 'primeng/progressspinner';
import { MultiSelect } from 'primeng/multiselect';
import { FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BuildPlatform, Project } from 'app/Models/project';
import { PublicationStateComponent } from "../../../ReusableComponents/publication-state/publication-state.component";
import { entityType } from 'app/Enumerations/entity-type.enum';
import { CompletionStateComponent } from "../../../ReusableComponents/completion-state/completion-state.component";
import { MessageService } from 'primeng/api';
import { InputText } from 'primeng/inputtext';
import { SelectChangeEvent } from 'primeng/select';
import { inputLength } from 'app/ConfigVariables/input-length';
import { ConfirmationPopupComponent } from "../../../Popups/confirmation-popup/confirmation-popup.component";
import { confirmDialogType } from 'app/Enumerations/confirm-dialog-type.enum';
import { publishState } from 'app/Enumerations/publish-state.enum';
import { Subscription } from 'rxjs';
import { responseCode } from 'app/Enumerations/response-code.enum';

@Component({
  selector: 'app-project',
  imports: [ProgressSpinner, MultiSelect, InputText, ReactiveFormsModule, PublicationStateComponent, CompletionStateComponent, ConfirmationPopupComponent],
  templateUrl: './project.component.html',
  styleUrl: './project.component.css'
})
export class ProjectComponent implements OnInit {

  requestLoading: boolean = true;
  updateRequestInProgress: boolean = false;
  project?: ProjectDetails;
  buildPlatforms: BuildPlatform[] = [];

  buildPlatformChanged: boolean = false;

  projectNameInput: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minProjectName)]);
  projectBuildPlatforms: FormControl = new FormControl("");

  itineraryIndexdToPublish: number = 0;

  // Enumerations
  enumEntityType = entityType;
  enumDialogType = confirmDialogType;
  enumPublishState = publishState;
  inputLength = inputLength;

  // Confirmation popup
  visibleConfirmationPopup: boolean = false;
  actionConfirmationPopup: number = 0;
  itineraryName: string = "";

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private messageService: MessageService,
  ) {

  }

  ngOnInit() {
    this.dataService.getBuildPlatforms().subscribe((buildPlatforms) => {
      this.buildPlatforms = buildPlatforms;
    });

    const projectIdFromRoute = this.route.snapshot.paramMap.get("id");
    if (projectIdFromRoute !== null) {
      this.dataService.getProject(projectIdFromRoute).subscribe((project) => {
        this.project = project;
        this.requestLoading = false;
        this.projectNameInput.setValue(project.identificationName);
        this.projectBuildPlatforms.setValue(project.buildPlatforms.map(bp => bp.id));

        this.project.itineraries.forEach((itinerary) => {
          this.subscriptions.add(
            this.dataService.getItineraryState(itinerary.id).subscribe((response) => {
              itinerary.state = response;
            }),
          );
        });
      });
    }
  }

  displayFileSize(size: number): string {
    if (size < 1000) {
      return "" + size + " o";
    } else if (size < 1_000_000) {
      return "" + (size / 1024).toFixed(1) + " Ko";
    } else if (size < 1_000_000_000) {
      return "" + (size / 1_048_576).toFixed(1) + " Mo";
    } else {
      return "" + (size / 1_073_741_824).toFixed(1) + " Go";
    }
  }

  isbuildPlatformListModified(event: SelectChangeEvent) {
    const originalValue = this.project?.buildPlatforms.map(bp => bp.id).sort();
    const newValue = event.value.sort();

    if (originalValue?.length !== newValue.length) {
      this.buildPlatformChanged = true;
      return;
    } else if (originalValue) {
      for (let i = 0; i < originalValue?.length; i++) {
        if (originalValue[i] !== newValue[i]) {
          this.buildPlatformChanged = true;
          return;
        }
      }
    }
    this.buildPlatformChanged = false;
    return;
  }

  // publishItinerary(itineraryIndex: number, itineraryName: string) {
  //   if (this.project?.itineraries[itineraryIndex].readyForPublication !== true) {
  //     this.messageService.clear();
  //     this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: "Le parcours doit être complet pour être publié" });
  //   } else if (this.project?.itineraries[itineraryIndex].state === this.enumPublishState.PublicationInProgress) {
  //     this.messageService.clear();
  //     this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: "Veuillez attendre que la publication en cours soit terminée" });
  //   } else {
  //     this.visibleConfirmationPopup = true;
  //     this.actionConfirmationPopup = confirmDialogType.PublishItinerary;
  //     this.itineraryName = itineraryName;
  //     this.itineraryIndexdToPublish = itineraryIndex;
  //   }
  // }

  publishItinerary(itineraryIndex: number, itineraryName: string, publicationType: confirmDialogType) {
    if (this.project?.itineraries[itineraryIndex].readyForPublication !== true) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: "Le parcours doit être complet pour être publié" });
    } else if (this.project?.itineraries[itineraryIndex].state === this.enumPublishState.PublicationInProgress) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: "Veuillez attendre que la publication en cours soit terminée" });
    } else {
      this.visibleConfirmationPopup = true;
      this.actionConfirmationPopup = publicationType;
      this.itineraryName = itineraryName;
      this.itineraryIndexdToPublish = itineraryIndex;
    }
  }

  confirmPublishItinerary(confirmAction: boolean) {
    if (confirmAction && this.project) {
      if (this.project.itineraries[this.itineraryIndexdToPublish].state === this.enumPublishState.PublicationInProgress) {
        this.messageService.add({ severity: "warn", summary: "Publication en cours", detail: "Veuillez attendre que la publication en cours soit terminée" });
      } else if (this.actionConfirmationPopup === confirmDialogType.ForceBuildItinerary) {
        this.dataService.publishItineraryForce(this.project.itineraries[this.itineraryIndexdToPublish].id).subscribe((response) => {
          if (response.errorCode === responseCode.PublicationStarted && this.project) {
            this.project.itineraries[this.itineraryIndexdToPublish].state = publishState.PublicationInProgress;
          } else {
            this.messageService.clear();
            this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: response.errorMessage });
          }
        });
      } else if (this.actionConfirmationPopup === confirmDialogType.PublishItinerary) {
        this.dataService.publishItinerary(this.project.itineraries[this.itineraryIndexdToPublish].id).subscribe((response) => {
          if (response.errorCode === responseCode.PublicationStarted && this.project) {
            this.project.itineraries[this.itineraryIndexdToPublish].state = publishState.PublicationInProgress;
          } else {
            this.messageService.clear();
            this.messageService.add({ severity: "warn", summary: "Publication impossible", detail: response.errorMessage });
          }
        });
      }
    }
    this.visibleConfirmationPopup = false;
  }

  updateProject() {
    if (this.isProjectComplete() && this.project) {
      this.updateRequestInProgress = true;
      const buildPlatforms: BuildPlatform[] = [];
      this.projectBuildPlatforms.value.forEach((platform: number) => {
        buildPlatforms.push(this.buildPlatforms[this.buildPlatforms.findIndex((bp) => bp.id === platform)]);
      });
      const project = new Project(this.project.id, this.projectNameInput.value, buildPlatforms);
      this.dataService.updateProject(project).subscribe((projectUpdated) => {
        this.buildPlatformChanged = false;
        if (this.project) {
          this.project.identificationName = projectUpdated.identificationName;
          this.project.buildPlatforms = projectUpdated.buildPlatforms;
        }
        this.updateRequestInProgress = false;
        this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "Le projet a été mis à jour" });
      });
    }
  }

  isProjectComplete(): boolean {
    if (!this.projectNameInput.valid) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom du projet doit comporter " + inputLength.minProjectName + " caractères minimum" });
      return false;
    } else if (this.projectBuildPlatforms.value.length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le projet doit comporter au moins une plateforme de build" });
      return false;
    } else {
      return true;
    }
  }
}
