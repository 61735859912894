@if (module) {
  <div class="previsualisation-container">
    @if (module.pointOfInterestId) {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()"/>
    }

    <div class="title-container">
      @if (displayModuleName()) {
        <span class="title">{{ displayModuleName() }}</span>
      } @else {
        <span class="title placeholder">Nom de l'activité</span>
      }
    </div>
    @switch (moduleCustomTemplate?.moduleKey) {
      @case ("ChartReference2025") {
        <img class="screenshot" src="assets/screenshots/charte-référence-2025.png" alt="image" />
      }
      @case ("ChartBio") {
        <img class="screenshot" src="assets/screenshots/charte-bio.png" alt="image" />
      }
      @case ("ChartRotation") {
        <img class="screenshot" src="assets/screenshots/charte-rotation.png" alt="image" />
      }
      @case ("TempsDeTravail") {
        <img class="screenshot" src="assets/screenshots/temps-de-travail.png" alt="image" />
      }
      @case ("RangementDuLocal") {
        <img class="screenshot" src="assets/screenshots/rangement-du-local.png" alt="image" />
      }
      @case ("TravailDuSol") {
        <img class="screenshot" src="assets/screenshots/travail-du-sol.png" alt="image" />
      }
      @case ("RFU") {
        <img class="screenshot" src="assets/screenshots/RFU.png" alt="image" />
      }
      @case ("OutilDeDecision") {
        <img class="screenshot" src="assets/screenshots/outil-de-décision.png" alt="image" />
      }
      @case ("MosaiqueHabitats") {
        <img class="screenshot" src="assets/screenshots/mosaïque-habitats.png" alt="image" />
      }
      @case ("Sondage") {
        <img class="screenshot" src="assets/screenshots/sondage.png" alt="image" />
      }
      @case ("PhotoSouvenirGroupe") {
        <img class="screenshot" src="assets/screenshots/photo-souvenir.png" alt="image" />
      }
      @case ("auxiliairesdescultures") {
        <img class="screenshot" src="assets/screenshots/auxiliaire-des-cultures.png" alt="image" />
      }
      @case ("MosaiqueHabitatsAuray") {
        <img class="screenshot" src="assets/screenshots/mosaïque-habitats-auray.png" alt="image" />
      }
      @case ("SyntheseAuray") {
        <img class="screenshot" src="assets/screenshots/synthèse-deux-systèmes.png" alt="image" />
      }
      @case ("MosaiqueHabitatsAuray") {
        <img class="screenshot" src="assets/screenshots/mosaïque-habitats-auray.png" alt="image" />
      }
      @case ("FairmontUniforme") {
        <img class="screenshot" src="assets/screenshots/uniforme-pour-fairmont.png" alt="image" />
      }
      @case ("FairmontChariot") {
        <img class="screenshot" src="assets/screenshots/chariot-pour-fairmont.png" alt="image" />
      }
      @case ("QuizOral1.3") {
        <img class="screenshot" src="assets/screenshots/les-fourrages.png" alt="image" />
      }
      @case ("QuizOral3.3") {
        <img class="screenshot" src="assets/screenshots/biosécurité-en-élevage.png" alt="image" />
      }
      @case ("TrevarezMenu") {
        <img class="screenshot" src="assets/screenshots/menus-de-trevarez.png" alt="image" />
      }
      @case ("MosaiqueHabitatsCrecom") {
        <img class="screenshot" src="assets/screenshots/mosaïque-habitats-crecom.png" alt="image" />
      }
      @case (null) {
        <p class="placeholder">Aucun type d'activité n'a été choisi</p>
      }
      @default {
        <p class="placeholder">Aucun aperçu disponible pour ce type d'activité</p>
      }
    }
  </div>
}
