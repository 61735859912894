@if (pointOfInterest) {
  <div class="previsualisation-container">
    <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()" />
    <div class="title-container">
      @if (displayPointOfInterestName()) {
        <span class="title">{{ displayPointOfInterestName() }}</span>
      } @else {
        <span class="title placeholder">Nom de l'étape</span>
      }
    </div>
    <div class="module-list">
      @for (module of pointOfInterest.modules; track $index) {
        @if (selectedLanguageId) {
          <div class="module-item">
            <div tabindex="0" aria-hidden="true" class="square" (click)="selectModule(module)" (keypress)="selectModule(module)">
              @switch (module.type) {
                @case (enumModuleType.Gallery) {
                  <img class="module-icon" src="assets/images/Icon_media.png" alt="image" />
                }
                @case (enumModuleType.QuizMultipleChoice) {
                  <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image" />
                }
                @case (enumModuleType.QuizTrueFalse) {
                  <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image" />
                }
                @case (enumModuleType.QuizIncremental) {
                  <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image" />
                }
                @case (enumModuleType.Custom) {
                  <img class="module-icon" src="assets/images/Activite_2X.png" alt="image" />
                }
                @case (enumModuleType.QuizParticipatory) {
                  <img class="module-icon" src="assets/images/Icone_Quiz_Oraux_Blanc.png" alt="image"/>
                }
              }
            </div>
            @if (!displayName(module)) {
              <div class="module-name placeholder">Nom de l'activité</div>
            } @else {
              <div class="module-name">{{ displayName(module) }}</div>
            }
          </div>
        }
      }
    </div>
    @if (pointOfInterest.modules?.length === 0) {
      <p class="placeholder" style="text-align: center">Aucune activité n’est associée à l’étape</p>
    }
  </div>
}
