<div class="justify-content-center">
  <p-dialog (onHide)="closePopup()" [dismissableMask]="true" header="Header" [modal]="true" [(visible)]="visible" [style]="{ width: '75vw' }" [draggable]="false">
    <ng-template pTemplate="header">
      @if (!mediaToDisplay) {
        <h2 class="text-xl font-bold">Ajouter un média</h2>
      } @else {
        <h2 class="text-xl font-bold">Visualiser un média</h2>
      }
    </ng-template>

    <form [formGroup]="mediaForm">
      @if (mediaToDisplay) {
        <div class="horizontal-display">
          <div class="media-container">
            @switch (mediaToDisplay.fileType) {
              @case (enumFileType.Pdf) {
                @if (mediaIsLoading) {
                  <p-progressSpinner></p-progressSpinner>
                }
                <button [disabled]="mediaIsLoading === true" class="button" [title]="'Télécharger le fichier ' + mediaToDisplay.name + mediaToDisplay.extension" (click)="downloadPdf()">
                  Télécharger le pdf
                </button>
              }
              @case (enumFileType.Video) {
                <video #video controls (loadeddata)="displayDimensionsVideo()" [title]="mediaToDisplay.name + mediaToDisplay.extension">
                  <source [src]="fileUrl" type="video/quicktime" />
                  <source [src]="fileUrl" type="video/mp4" />
                </video>
              }
              @case (enumFileType.Image) {
                @if (mediaIsLoading) {
                  <p-progressSpinner></p-progressSpinner>
                } @else {
                  <img #image [src]="fileUrl" [alt]="mediaToDisplay.name + mediaToDisplay.extension" [title]="mediaToDisplay.name + mediaToDisplay.extension" (load)="displayDimensionsImage()" />
                }
              }
              @case (enumFileType.Audio) {
                <audio controls [src]="fileUrl" [title]="mediaToDisplay.name + mediaToDisplay.extension"></audio>
              }
            }
          </div>

          <div class="border"></div>

          <div class="infos-media">
            <h3 class="no-margin">Informations du média</h3>
            <div class="rename-file-zone-visualization">
              @if (isRenameFileAfterUpload) {
                <div>
                  <textarea id="mediaLabel" rows="1" pTextarea formControlName="mediaLabel" autoResize="true" [maxlength]="inputLength.maxMediaLabel"> </textarea>
                  <p class="char-count">({{ countMediaLabelCharacters() }}/100)</p>
                </div>
                <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="confirmRenameFileAfterUpload()" (keypress)="confirmRenameFileAfterUpload()"> </i>
                <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Abandonner les changements" (click)="cancelRenameFile()" (keypress)="cancelRenameFile()"> </i>
              } @else {
                <p class="no-margin">
                  Nom: <strong>{{ mediaToDisplay.name }}</strong>
                </p>
                <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="renameFileAfterUpload()" (keypress)="renameFileAfterUpload()"> </i>
              }
            </div>

            <p class="no-margin">
              Extension: <strong>{{ mediaToDisplay.extension | lowercase }}</strong>
            </p>
            <p>
              Type: <strong>{{ displayFileType(mediaToDisplay.fileType) }}</strong>
            </p>
            <p>
              Poids: <strong>{{ displayFileSize(mediaToDisplay.size) }}</strong>
            </p>
            @if ((mediaIsLoading === false && mediaToDisplay.fileType === enumFileType.Image) || mediaToDisplay.fileType === enumFileType.Video) {
              <p>
                Dimensions: <strong>{{ dimensionsMedia[0] + " x " + dimensionsMedia[1] }} px</strong>
              </p>
            } @else {
              <p [style]="{ color: 'white' }">.</p>
            }

            <div>
              @if (modulesAssociatedToMedia.length > 0 && modulesAssociatedAreLoading === false) {
                <h3 class="">Ce média est utilisé dans les activités suivantes :</h3>
                @for (module of modulesAssociatedToMedia; track module.id) {
                  <a routerLink="/{{ this.getProjectName() }}/module-management/{{ module.id }}">
                    <div class="module-associated">
                      <p class="blue-color">{{ module.identificationName }}</p>
                      <p>Utilisé {{ module.mediaCount }} fois</p>
                      <!-- <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Modifier l'activité" (click)="renameFileAfterUpload()" (keypress)="renameFileAfterUpload()"> </i> -->
                    </div>
                  </a>
                }
              } @else if (modulesAssociatedAreLoading === true) {
                <div class="spinner-load-modules">
                  <p-progressSpinner></p-progressSpinner>
                </div>
              } @else {
                <p>Ce média n'est pas utilisé dans une activité.</p>
              }
            </div>
          </div>
        </div>
      } @else {
        <div class="margin-bottom">
          <input #fileUploader class="file-uploader" type="file" multiple="true" id="fileUploader" accept=".mov,.mp4,.mp3,.wav,.aiff,.jpeg,.jpg,.png,.pdf" (change)="addFiles()" />
          <!--// mov mp4
          // mp3 wav aiff
          // jpg png jpeg -->
          <div class="drag-zone" (drop)="fileDropHandler($event)" (dragover)="dragFileOverHandler($event)" (paste)="filePasteHandler($event)" title="Déposer vos fichier dans cette section">
            <p>Déposez des fichiers ici</p>
            <p>- OU -</p>
            <button class="button btn-browse-files" type="button" [disabled]="uploadStarted" (click)="clickFileUploader()" title="Cliquer pour ouvrir l'explorateur de fichiers">
              Parcourir vos fichiers
            </button>
            <p class="no-margin">Les fichiers acceptés sont les suivants : pdf, images (png, jpg), vidéo (mp4, mov)</p>
            <p class="no-margin">La taille maximale des fichiers acceptés est de 500 Mo.</p>
            <p class="no-margin">Le nombre de caractères de chaque fichier sera tronqué à 100 caractères s'il est supérieur à cette limite.</p>
          </div>
          <div class="header-file-list">
            @if (uploadedFiles.length > 0) {
              <p>{{ uploadedFiles.length }} fichiers sélectionnés:</p>
            }
            @if (uploadStarted) {
              <div class="progress-bar-zone">
                <p>Ajout en cours: {{ displayUploadedFilesCount() }}/{{ uploadedFiles.length }} fichiers</p>
                <p-progressSpinner [style]="{ width: '2rem', height: '2rem' }"></p-progressSpinner>
                <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Annuler tous les fichiers" (click)="cancelAllUploads()" (keypress)="cancelAllUploads()"> </i>
              </div>
            }
          </div>

          @for (file of uploadedFiles; track file; let index = $index) {
            <div class="new-file">
              <div class="horizontal-display">
                <div class="rename-file-zone">
                  @if (indexFileToRename === index) {
                    <div class="vertical-display">
                      <input pInputText id="mediaLabel" formControlName="mediaLabel" [maxlength]="inputLength.maxMediaLabel" (keydown.enter)="confirmRenameFile(index)" />
                      <p class="align-right">({{ countMediaLabelCharacters() }}/100)</p>
                    </div>
                    <p>{{ displayExtention(file.name) | lowercase }}</p>
                    <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="confirmRenameFile(index)" (keypress)="confirmRenameFile(index)"> </i>
                    <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Abandonner les changements" (click)="cancelRenameFile()" (keypress)="cancelRenameFile()"> </i>
                  } @else {
                    <p class="no-margin">{{ file.name }}</p>
                    @if (this.allowedFileTypes.indexOf(file.type) === -1 || file.size > this.fileSizeMax) {
                      <i class="warning bi bi-exclamation-triangle" title="{{ setWarningTitle(file) }}"></i>
                    } @else if (!uploadStarted) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="renameFile(index)" (keypress)="renameFile(index)"> </i>
                    }
                  }
                </div>
                <div class="horizontal-display infos-new-files">
                  @if (this.allowedFileTypes.indexOf(file.type) !== -1) {
                    <p class="no-margin file-type">{{ displayMimeFileType(file.type) | uppercase }}</p>
                    <p class="no-margin file-size" [ngClass]="{ 'invalid-size': file.size > this.fileSizeMax }">{{ displayFileSize(file.size) }}</p>
                  }
                  @if (!uploadStarted) {
                    <i tabIndex="0" aria-hidden="true" class="bi bi-trash btn-action-file" title="Supprimer" (click)="removeFile(file)" (keypress)="removeFile(file)"> </i>
                  }
                </div>
              </div>
              @if (uploadStarted) {
                <div class="progress-container">
                  <p-progressBar #progressBar [value]="progressArray[index]"></p-progressBar>
                  <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Annuler" (click)="cancelUpload(file)" (keypress)="cancelUpload(file)"> </i>
                </div>
              }
            </div>
          }
        </div>
      }
    </form>

    <ng-template pTemplate="footer">
      @if (!mediaToDisplay) {
        <button [disabled]="uploadStarted" class="button confirm-action-button" (click)="upload()" [ngClass]="uploadedFiles.length ? null : 'button-disabled'">
          <span title="Confirmer l'upload des médias">Ajouter</span>
        </button>
        <button [disabled]="uploadStarted" class="button" (click)="visible = false" title="Annuler l'upload des médias">Annuler</button>
      } @else {
        <button [disabled]="uploadStarted" class="button" (click)="visible = false" title="Retour à la Bibliothèque">Retour</button>
      }
    </ng-template>
  </p-dialog>
</div>
