@if (modeCreation) {
  <div class="form-container">
    <h3>Nouveau module spécial</h3>
    <form [formGroup]="newModuleCustomForm">
      <label htmlFor="moduleKey">Clef du module</label>
      <input pInputText id="moduleKey" formControlName="moduleKey" />
      <label htmlFor="name">Nom du module</label>
      <input pInputText id="name" formControlName="name" />
      <label htmlFor="description">Description du module</label>
      <input pInputText id="name" formControlName="description" />
    </form>
    <button class="button margin-left" (click)="createModuleCustom()">Créer</button>
  </div>
} @else {
  <button class="button" (click)="modeCreation = true">Créer un module spécial</button>
}
<button class="button" (click)="saveModuleAssociations()">Sauvegarder</button>
@if (moduleCustomList.length > 0) {
  <table>
    <thead class="membership-tiers text-left">
      <tr>
        <th>Nom</th>
        <th>Clé</th>
        @for (project of projects; track $index; let columnIndex = $index) {
          <th class="rotated-text" scope="col">{{ project.identificationName }}</th>
        }
      </tr>
    </thead>
    <tbody>
      @for (module of moduleCustomList; let rowIndex = $index; track rowIndex) {
        <tr>
          <td>{{ module.name }}</td>
          <td>{{ module.moduleKey }}</td>
          @for (project of projects; let columnIndex = $index; track $index) {
            <td>
              <p-checkbox [disabled]="this.disableCheckboxes[rowIndex][columnIndex]" [binary]="true" [(ngModel)]="associations[rowIndex][columnIndex]" (click)="messageCheckbox(rowIndex, columnIndex)"/>
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
} @else {
  <p>Aucun module n'a été créé.</p>
}

<app-module-custom-popup
  [visible]="visiblePopup"
  [moduleCustomAssociations]="modifications"
  [projects]="projects"
  [modules]="moduleCustomList"
  (confirmActionEmitter)="processModuleAssociations($event)">
</app-module-custom-popup>
