import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { DatePipe } from "@angular/common";
import { inputLength } from "app/ConfigVariables/input-length";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { modulePageAction } from "app/Enumerations/module-page-action.enum";
import { moduleTypeToString, moduleType } from "app/Enumerations/module-type.enum";
import { getPublishStateValue as getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { responseCode } from "app/Enumerations/response-code.enum";
import { Language } from "app/Models/language";
import { Media } from "app/Models/media";
import { MediaAssociation, Module, ModuleCustomTemplate } from "app/Models/module";
import { PointOfInterest } from "app/Models/point-of-interest";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { ModuleService } from "app/Services/Module-Service/module.service";
import { MenuItem, MessageService, PrimeTemplate } from "primeng/api";
import { PublicationStateComponent } from "../../../ReusableComponents/publication-state/publication-state.component";
import { SplitButton } from "primeng/splitbutton";
import { ModuleConfigurationComponent } from "../module-configuration/module-configuration.component";
import { ModuleMultilanguageContentComponent } from "../module-multilanguage-content/module-multilanguage-content.component";
import { ModuleCustomComponent } from "../module-custom/module-custom.component";
import { TableModule } from "primeng/table";
import { FormsModule } from "@angular/forms";
import { InputText } from "primeng/inputtext";
import { CompletionStateComponent } from "../../../ReusableComponents/completion-state/completion-state.component";
import { ConfirmationPopupComponent } from "../../../Popups/confirmation-popup/confirmation-popup.component";

@Component({
  selector: "app-module-management-page",
  templateUrl: "./module-management-page.component.html",
  styleUrl: "./module-management-page.component.css",
  imports: [
    PublicationStateComponent,
    RouterLink,
    SplitButton,
    ModuleConfigurationComponent,
    ModuleMultilanguageContentComponent,
    ModuleCustomComponent,
    TableModule,
    PrimeTemplate,
    FormsModule,
    InputText,
    CompletionStateComponent,
    ConfirmationPopupComponent,
    DatePipe,
  ],
})
export class ModuleManagementPageComponent implements OnInit, OnDestroy {
  pageAction: number = 0;

  modulePageInfos?: Partial<Module>;
  // module?: Module;
  moduleList: Module[] = [];
  filteredModuleList: Module[] = [];
  selectedModuleList: Module[] = [];

  filterValue: string = "";

  mediaList: Media[] = [];
  languagesList: Language[] = [];

  typeModuleSelected: number = -1;

  isPageStarting: boolean = true;

  pointOfInterestList: PointOfInterest[] = [];
  associatedMediaList: MediaAssociation[] = [];
  moduleCustomTemplates: ModuleCustomTemplate[] = [];

  // Confirmation dialog attributes
  isConfirmationPopupVisible: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;
  moduleIdToProcess!: number;
  multipleDeletion!: boolean;

  //Enumerations
  enumModuleType = moduleType;
  enumPublishState = publishState;
  enumPageAction = modulePageAction;
  enumEntityType = entityType;

  //splitButton attributes
  menuItems: MenuItem[] = [];

  inputLength = inputLength;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    const routePath = this.route.snapshot.routeConfig?.path;
    const moduleIdFromRoute = this.route.snapshot.paramMap.get("id");

    if (routePath != null && routePath === `:projectName/module-management`) {
      this.pageAction = modulePageAction.ListVisualisation;
      this.dataService.getModules().subscribe({
        next: (modules) => {
          this.moduleList = modules;
          this.filteredModuleList = this.moduleList;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else if (routePath != null && routePath === `:projectName/module-management/new-module`) {
      this.pageAction = modulePageAction.Creation;
      this.typeModuleSelected = history.state.moduleType;

      this.dataService.getLanguages().subscribe((languages) => {
        this.languagesList = languages;
      });

      this.moduleService.initializeNewModule(this.typeModuleSelected);

      this.dataService.getModuleCustomTemplates().subscribe({
        next: (moduleCustomTemplates) => {
          this.moduleCustomTemplates = moduleCustomTemplates;
        },
      });

      this.dataService.getAllPointsOfInterest().subscribe({
        next: (pointsOfInterest) => {
          this.pointOfInterestList = pointsOfInterest;
        },
        error: (error) => {
          console.log(error.message);
        },
      });

      this.dataService.getMedias().subscribe({
        next: (medias) => {
          this.mediaList = medias;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else if (routePath != null && routePath === `:projectName/module-management/type-selection`) {
      this.pageAction = modulePageAction.TypeSelection;
    } else if (moduleIdFromRoute != null) {
      this.pageAction = modulePageAction.Modification;
      // TODO: Think about a better way to do this (forkJoin by rxjs exemple on itinerary page)
      this.dataService.getLanguages().subscribe((languages) => {
        this.languagesList = languages;
        this.moduleService.fetchModule(moduleIdFromRoute);
      });

      this.moduleService.moduleType.subscribe((type) => {
        this.modulePageInfos = this.moduleService.getModulePageInfos();
        this.modulePageInfos.type = type;
      });

      this.menuItems = [
        // {
        //   label: 'Sauvegarder et dupliquer',
        //   title: 'Sauvegarder et dupliquer l\'activité',
        //   command: () => {
        //     this.updateModule(true);
        //   },
        // },
        {
          label: "Supprimer",
          title: "Supprimer l'activité",
          command: () => {
            this.showConfirmationPopup(Number(moduleIdFromRoute), false);
          },
        },
      ];

      this.dataService.getAllPointsOfInterest().subscribe({
        next: (pointsOfInterest) => {
          this.pointOfInterestList = pointsOfInterest;
        },
        error: (error) => {
          console.log(error.message);
        },
      });

      this.dataService.getModuleCustomTemplates().subscribe({
        next: (moduleCustomTemplates) => {
          this.moduleCustomTemplates = moduleCustomTemplates;
        },
      });

      this.dataService.getMedias().subscribe({
        next: (medias) => {
          this.mediaList = medias;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    }
    this.setPageTitle();
  }

  ngOnDestroy() {
    this.moduleService.resetModuleVar();
  }

  getModuleState() {
    if (this.modulePageInfos && this.modulePageInfos.state) {
      return getPublishStateValue(this.modulePageInfos.state);
    } else {
      return "";
    }
  }

  moduleTypeToString(type: number): string {
    return moduleTypeToString(type);
  }

  showForm(moduleType: number) {
    this.router.navigateByUrl(`${this.authService.getProjectName()}/module-management/new-module`, {
      state: {
        moduleType: moduleType,
        pointOfInterestId: history.state.pointOfInterestId,
      },
    });
  }

  displayModuleType(): string {
    return moduleTypeToString(this.typeModuleSelected);
  }

  /*duplicateModule(module: Module) {
    if (this.pageAction !== modulePageAction.Creation) {
      module.id = 0;
      module.identificationName = module.identificationName + '-Copie';
      module.lastModificationDate = undefined;
      module.publicationDate = undefined;
      module.state = this.enumPublishState.NotPublished;
      module.order = -1;

      module.moduleTranslations.forEach(translation => {
        translation.translatedEntityId = 0;
      });

      if (module.type === moduleType.QuizTrueFalse || module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizIncremental) {
        module.questions.forEach(question => {
          question.id = 0;
          if (module.type !== moduleType.QuizTrueFalse) {
            question.answers.forEach(answer => {
              answer.id = 0;
            });
          }
        })
      }

      // const mediaAssociationList = this.updateMediaAssociations();

      // const newModule: Module = {
      //   id: 0,
      //   pointOfInterestId: this.moduleForm.get(['associatedPOI'])?.value,
      //   identificationName: moduleName,
      //   state: this.enumPublishState.NotPublished,
      //   order: -1,
      //   readyForPublication: false,
      //   moduleTranslations: this.moduleService.getModule().moduleTranslations,
      //   mediaAssociations: mediaAssociationList,
      //   questions: this.moduleService.getQuizQuestions(),
      //   moduleCustomTemplateId: this.moduleService.getModule().moduleCustomTemplateId
      // };

      switch(module.type) {
        case moduleType.Gallery:
          // const mediaAssociationList = this.updateMediaAssociations();
          // module.mediaAssociations = mediaAssociationList;
          this.dataService.createModuleGallery(module).subscribe({
            next: (module) => {
              this.navigateToNewModule(module);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
          break;
        case moduleType.QuizTrueFalse:
          this.dataService.createModuleQuizTrueFalse(module).subscribe({
            next: (module) => {
              this.navigateToNewModule(module);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
          break;
        case moduleType.QuizMultipleChoice:
          this.dataService.createModuleQuizMultipleChoice(module).subscribe({
            next: (receivedModule) => {
              this.navigateToNewModule(receivedModule);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
          break;
        case moduleType.QuizIncremental:
          this.dataService.createModuleQuizIncremental(module).subscribe({
            next: (module) => {
              this.navigateToNewModule(module);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
          break;
        case moduleType.Custom:
          this.dataService.createModuleCustom(module).subscribe({
            next: (module) => {
              this.navigateToNewModule(module);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
          break;
      }
    }
  }*/

  createModule() {
    if (this.moduleService.isModuleValid()) {
      this.moduleService.createModule().subscribe({
        next: (module) => {
          this.navigateToNewModule(module);
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else {
      this.messageService.add({ severity: "warn", summary: "Champ invalide", detail: "Le label du module être compris entre " + 1 + " et " + inputLength.maxModuleLabel + " caractères" });
    }
  }

  navigateToNewModule(module: Module) {
    this.messageService.add({ severity: "success", summary: "Création réussie", detail: "L'activité a bien été créé" });
    this.navigate("module-management/" + module.id);
  }

  updateModule(isDuplication: boolean) {
    if (this.moduleService.isModuleValid()) {
      this.moduleService.updateModule(false).subscribe({
        next: (data) => {
          this.modulePageInfos = data.data;

          if (isDuplication === true) {
            // think about how to implement duplicate module
            // this.duplicateModule(newModuleWithInfos);
          } else {
            switch (data.errorCode) {
              case responseCode.SuccessfulUpdate:
                this.messageService.add({ severity: "success", summary: "Modification réussie", detail: data.errorMessage });
                break;
              case responseCode.NotModified:
                this.messageService.add({ severity: "warn", summary: "Aucune modification", detail: data.errorMessage });
                break;
              case responseCode.ImpossibleUpdate:
                this.messageService.add({ severity: "error", summary: "Modification impossible", detail: data.errorMessage });
                break;
            }
          }
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else {
      this.messageService.add({ severity: "warn", summary: "Modification impossible", detail: "Le label du module être compris entre " + 1 + " et " + inputLength.maxModuleLabel + " caractères" });
    }
  }

  deleteModule() {
    this.isConfirmationPopupVisible = false;

    this.dataService.deleteModule(this.moduleIdToProcess).subscribe({
      next: (result) => {
        switch (result.errorCode) {
          case responseCode.SuccessfulDeletion:
            this.moduleList = this.moduleList.filter((module) => module.id !== this.moduleIdToProcess);
            this.messageService.add({ severity: "success", summary: "Suppression réussie", detail: result.errorMessage });
            this.router.navigateByUrl(`${this.authService.getProjectName()}/module-management`);
            break;
          case responseCode.ImpossibleDeletion:
            this.messageService.add({ severity: "error", summary: "Suppression impossible", detail: result.errorMessage });
            break;
        }
        this.filteredModuleList = this.moduleList;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteMultipleModules() {
    const idList: number[] = [];

    this.selectedModuleList.forEach((moduleToDelete) => {
      idList.push(moduleToDelete.id);
    });
    this.dataService.deleteModules(idList).subscribe({
      next: (result) => {
        switch (result.errorCode) {
          case responseCode.SuccessfulDeletion:
            this.moduleList = this.moduleList.filter((module) => !idList.includes(module.id));
            this.selectedModuleList = [];
            this.messageService.add({ severity: "success", summary: "Activités supprimées", detail: "Les activités sélectionnées ont bien été supprimées" });
            break;
          case responseCode.ImpossibleDeletion:
            this.moduleList = this.moduleList.filter((module) => !result.data.includes(module.id));
            this.selectedModuleList = [];
            this.messageService.add({ severity: "error", summary: "Erreur lors de la suppression", detail: result.errorMessage });
            break;
        }
        this.filteredModuleList = this.moduleList;
      },
      error: (error) => {
        this.messageService.add({ severity: "error", summary: "Erreur lors de la suppression", detail: "Une erreur inconnue est survenue" });
        console.log(error.message);
      },
    });
  }

  applyGlobalFilter() {
    this.filteredModuleList = this.moduleList;
    this.filteredModuleList = this.filteredModuleList.filter((item) => {
      return this.matchesFilter(item, this.filterValue);
    });
  }

  matchesFilter(item: Module, filterValue: string): boolean {
    filterValue = filterValue.toLowerCase();

    // TODO: maybe we should cache these values when initing the modules
    // This is wasted performance to redo all the transform/lowercase every time
    const formattedDate = this.datePipe.transform(item.createdAt, "yyyy-MM-dd' 'HH:mm:ss");
    const nameMatches = item.identificationName.toLowerCase().includes(filterValue);
    const dateMatches = formattedDate?.includes(filterValue);
    const moduleTypeMatches = moduleTypeToString(item.type).toLowerCase().includes(filterValue);

    return nameMatches || dateMatches || moduleTypeMatches;
  }

  navigate(url: string) {
    const projectName = this.authService.getProjectName();
    this.router.navigateByUrl(`${projectName}/${url}`);
  }

  navigateLink(event: MouseEvent, url: string) {
    const projectName = this.authService.getProjectName();
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      window.open(`${projectName}/${url}`, "_blank");
    } else {
      this.router.navigateByUrl(`${projectName}/${url}`);
    }
  }

  //Confirmation popup functions
  showConfirmationPopup(moduleId: number, multipleDeletion: boolean) {
    if (multipleDeletion == true && this.selectedModuleList.length == 0) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Aucune activité séletionnée", detail: "Sélectionnez une ou plusieurs activités pour effectuer cette action" });
    } else {
      this.moduleIdToProcess = moduleId;
      this.multipleDeletion = multipleDeletion;
      this.confirmTypeAction = confirmDialogType.Delete;
      this.entityTypeAction = entityType.Module;
      this.isConfirmationPopupVisible = true;
    }
  }

  confirmActionDialog(confirmAction: boolean) {
    this.isConfirmationPopupVisible = false;
    if (confirmAction == true) {
      if (this.multipleDeletion == false) {
        this.deleteModule();
      } else {
        this.deleteMultipleModules();
      }
    }
  }

  storeEntity() {
    let moduleType = "";
    switch (this.modulePageInfos?.type) {
      case this.enumModuleType.QuizMultipleChoice:
      case this.enumModuleType.QuizTrueFalse:
        moduleType = "quiz";
        break;

      case this.enumModuleType.QuizIncremental:
        moduleType = "quizIncremental";
        break;

      case this.enumModuleType.Gallery:
        moduleType = "gallery";
        break;

      case this.enumModuleType.Custom:
        moduleType = "custom";
        break;

      case this.enumModuleType.QuizParticipatory:
        moduleType = 'quizParticipatory'
        break;
    }
    localStorage.setItem("entityType", moduleType);
    if (this.modulePageInfos?.id) {
      localStorage.setItem("entityId", this.modulePageInfos?.id.toString());
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    switch (this.pageAction) {
      case this.enumPageAction.Creation:
      case this.enumPageAction.TypeSelection:
        document.title = "Activité - Création";
        break;

      case this.enumPageAction.ListVisualisation:
        document.title = "Activités";
        break;

      case this.enumPageAction.Modification:
        if (this.modulePageInfos && this.modulePageInfos.identificationName) {
          document.title = "Activité - " + this.modulePageInfos.identificationName;
        }
        break;
    }
  }
}
