@if (requestLoading) {
    <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
} @else if (project) {
    <div class="horizontal-display">
        <h2>{{ project.identificationName }}</h2>
        <button class="button save-button" [disabled]="updateRequestInProgress" (click)="updateProject()" title="Sauvegarder le parcours">Sauvegarder
          @if (updateRequestInProgress) {
            <p-progressSpinner class="spinner" [style]="{ width: '1rem', height: '1rem' }" />
          }
        </button>
    </div>


    <h5 class="no-margin-bottom">Nom du projet @if(projectNameInput.value !== project.identificationName) {<span>(modifié)</span>}</h5>
    <input pInputText id="projectName" [formControl]="projectNameInput" />

    <h5 class="no-margin-bottom">buildPlatforms @if(buildPlatformChanged === true) {<span>(modifié)</span>}</h5>
    <p-multiSelect
        [style]="{ width: '100%' }"
        [showHeader]="false"
        [options]="buildPlatforms"
        optionLabel="name"
        optionValue="id"
        placeholder="Choisir une/des plateformes de build"
        emptyMessage="Aucune plateforme de build n'a été trouvée"
        [formControl]="projectBuildPlatforms"
        (onChange)="isbuildPlatformListModified($event)">
        <ng-template let-value pTemplate="selectedItems">
          <div class="elements-p-multiselect">
            @for (option of value; let optionIndex = $index; track optionIndex) {
              <p class="truncate-one-line">{{ option.name }}</p>
              <p>
                @if (optionIndex !== value.length - 1) {
                  ,
                }
              </p>
            }
            @if (!value || value.length === 0) {
              Choisir une plateforme
            }
          </div>
        </ng-template>
    </p-multiSelect>


    <h5>Utilisateurs</h5>
    <table>
        <tr>
          <th>Id</th>
          <th>Adresse e-mail</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Role</th>
        </tr>
        @for (user of project.users; track $index) {
          <tr class="user-table">
            <td>{{ user.id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.surname }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.role }}</td>
          </tr>
        }
        @if (project.users.length === 0) {
            <td>Le project ne contient aucun utilisateur.</td>
        }
    </table>

    <h5>Parcours</h5>
    <table>
        <tr>
          <th>Id</th>
          <th>Nom</th>
          <th>État de publication</th>
          <th>État de complétion</th>
          <th>Actions</th>
        </tr>
        @for (itinerary of project.itineraries; track $index) {
          <tr class="user-table">
            <td>{{ itinerary.id }}</td>
            <td>{{ itinerary.identificationName }}</td>
            <td class="table-user-item">
              <app-publication-state [entityType]="enumEntityType.Itinerary" [publicationState]="itinerary.state"></app-publication-state>
              @if (itinerary.state === enumPublishState.PublicationInProgress) {
                <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
              }
            </td>
            <td><app-completion-state [completionState]="itinerary.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'"></app-completion-state></td>
            <td>
              <i tabIndex="0" aria-hidden="true" class="bi bi-send btn-action-file" title="Publier le parcours" (click)="publishItinerary($index, itinerary.identificationName, enumDialogType.PublishItinerary)" (keypress)="publishItinerary($index, itinerary.identificationName, enumDialogType.PublishItinerary)"> </i>
              <i tabIndex="0" aria-hidden="true" class="bi bi-arrow-repeat btn-action-file" title="Forcer la publication" (click)="publishItinerary($index, itinerary.identificationName, enumDialogType.ForceBuildItinerary)" (keypress)="publishItinerary($index, itinerary.identificationName, enumDialogType.ForceBuildItinerary)"> </i>
            </td>
        </tr>
        }
        @if (project.itineraries.length === 0) {
          <td>Le project ne contient aucun parcours.</td>
        }
    </table>

    <h5>Modules Spéciaux</h5>
    <table>
        <tr>
          <th>Id</th>
          <th>Clef</th>
          <th>Nom</th>
          <th>Description</th>
        </tr>
        @for (module of project.moduleCustomTemplates; track $index) {
          <tr class="user-table">
            <td>{{ module.id }}</td>
            <td>{{ module.moduleKey }}</td>
            <td>{{ module.name }}</td>
            <td>{{ module.description }}</td>
        </tr>
        }
        @if (project.moduleCustomTemplates.length === 0) {
            <td>Le project ne contient aucun module spécial.</td>
        }
    </table>

    <h5>Utilisation du disque du serveur</h5>
    <p> - Espace utilisé par la bibliothèque: {{ displayFileSize(project.diskUsage) }} pour {{ project.mediaCount }} médias (moyenne de {{ displayFileSize(project.diskUsage  / project.mediaCount) }} par média).</p>
}

<app-confirmation-popup
  [visible]="visibleConfirmationPopup"
  [entityTypeAction]="enumEntityType.Itinerary"
  [confirmTypeAction]="actionConfirmationPopup"
  [confirmationLabel]="itineraryName"
  (confirmActionEmitter)="confirmPublishItinerary($event)">
</app-confirmation-popup>
