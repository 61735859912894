export enum responseCode {
  //Login Codes 1-10
  SuccessfulLogin = 1,
  UserNotFound = 2,
  WrongPassword = 3,
  ResetPasswordEmailSent = 4,
  SuccessfulPasswordReset = 5,
  ErrorPasswordReset = 6,
  SuccessfulConfirmEmail = 7,
  ErrorConfirmEmail = 8,
  EmailAlreadyConfirmed = 9,
  GoToProjectSelection = 10,

  //Entities 11-20
  SuccessfulUpdate = 11,
  NotModified = 12,
  SuccessfulDeletion = 13,
  ImpossibleUpdate = 14,
  ImpossibleDeletion = 15,
  SuccessfulCreation = 16,
  ImpossibleCreation = 17,

  //Medias 21-30
  MediaUploadFailed = 21,
  MediaDeletionImpossible = 22,
  MediaSuppressed = 23,

  // Publication
  PublicationStarted = 37,
  ImpossiblePublication = 38,
  ItineraryDoesNotHaveBuildPlatform = 39,

  //Generic error 50-60
  ErrorUndefined = 50,
}
