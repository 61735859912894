<div>
  <p-dialog header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '75vw' }" (onHide)="closePopup()">
    <ng-template pTemplate="header">
      <h2 class="text-xl font-bold">Publication non complétée</h2>
    </ng-template>

    @if (publication) {
      <div class="horizontal-display">
        <div class="identification-section">
          @if (showPointOfInterests === true) {
            <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier parcours" (click)="showPointOfInterests = false"> </i>
          } @else {
            <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier parcours" (click)="showPointOfInterests = true"> </i>
          }
          <p class="itinerary-label" aria-hidden="true" tabindex="0">Parcours: {{ publication.identificationName }}</p>
        </div>

        <app-completion-state [completionState]="publication.readyForPublication" [tooltipTrue]="'Le parcours est complet'" [tooltipFalse]="'Le parcours n\'est pas complet'"> </app-completion-state>

        <i tabIndex="0" aria-hidden="true" class="bi bi-eye action-buttons-item margin-right" title="Visualiser le parcours" (keypress)="closePopup()" (click)="closePopup()"> </i>
      </div>

      @if (showPointOfInterests) {
        @if (publication.pointOfInterests.length === 0) {
          <p class="POI-label">Aucune étape n'est associée au parcours.</p>
        } @else {
          @for (pointOfInterest of publication.pointOfInterests; track $index) {
            <div class="horizontal-line"></div>

            <div class="horizontal-display">
              <div class="identification-section POI-label">
                @if (showModules[$index]) {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier l'étape" (click)="showModules[$index] = false"> </i>
                } @else {
                  <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier l'étape" (click)="showModules[$index] = true"> </i>
                }

                <p aria-hidden="true" tabindex="0">Étape: {{ pointOfInterest.identificationName }}</p>
              </div>

              <app-completion-state [completionState]="pointOfInterest.readyForPublication" [tooltipTrue]="'L\'étape est complète'" [tooltipFalse]="'L\'étape n\'est pas complète'">
              </app-completion-state>

              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-eye action-buttons-item margin-right"
                title="Visualiser l'étape"
                (keypress)="navigate('point-of-interest-management/' + pointOfInterest.id)"
                (click)="navigate('point-of-interest-management/' + pointOfInterest.id)">
              </i>
            </div>

            <!-- For now we always display modules, I don't think it's working properly otherwise -->
            <!--@if (showModules[$index]) {-->
            @if (true) {
              @if (pointOfInterest.modules.length === 0) {
                <p class="module-label">Aucune activité n'est associée à l'étape.</p>
              } @else {
                @for (module of pointOfInterest.modules; track $index) {
                  <div class="horizontal-display">
                    <p class="identification-section module-label" aria-hidden="true" tabindex="0">Activité: {{ module.identificationName }}</p>

                    <app-completion-state [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
                    </app-completion-state>

                    <i
                      tabIndex="0"
                      aria-hidden="true"
                      class="bi bi-eye action-buttons-item margin-right"
                      title="Visualiser l'activité"
                      (keypress)="navigate('module-management/' + module.id)"
                      (click)="navigate('module-management/' + module.id)">
                    </i>
                  </div>
                }
              }
            }
          }
        }
      }
    }

    <!-- <p>Les éléments suivants du parcours ne sont pas complétés.</p> -->

    <ng-template pTemplate="footer">
      <button class="button" (click)="closePopup()">Quitter</button>
    </ng-template>
  </p-dialog>
</div>
