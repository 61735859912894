<div class="flex justify-content-center">
  <p-dialog header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw' }"
    (onHide)="closePopup(false)">
    <ng-template pTemplate="header">
      <h2 class="text-xl font-bold">Choisir une icône</h2>
    </ng-template>

    <div class="icon-container">
      <div class="icon-grid">
        @for (icon of icons; track $index) {
          <i [class]="icon" [ngClass]="{ 'selected': icon === selectedIcon }" (click)="selectIcon(icon)" class="bi icon"></i>
        }
      </div>
    </div>

    <ng-template pTemplate="footer">
      <button class="button confirm-action-button" (click)="closePopup(true)">Confirmer</button>
      <button class="button" (click)="closePopup(false)" title="Annuler l'action">Annuler</button>
    </ng-template>
  </p-dialog>
</div>