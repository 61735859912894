export enum entityType {
  Area = 1,
  Itinerary = 2,
  PointOfInterest = 3,
  Module = 4,
  Media = 5,
  Marker = 6,
  User = 7,
  Category = 8,
}
