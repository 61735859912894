import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { ItineraryDetail, ItineraryTranslation } from "app/Models/itineraryDetail";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { MenuItem, MessageService } from "primeng/api";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { Language } from "app/Models/language";
import { getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { ItineraryPublication } from "app/Models/publication";
import { Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { responseCode } from "app/Enumerations/response-code.enum";
import { PointOfInterest } from "app/Models/point-of-interest";
import { Media } from "app/Models/media";
import { PublicationStateComponent } from "../../../ReusableComponents/publication-state/publication-state.component";
import { ProgressSpinner } from "primeng/progressspinner";
import { SplitButton } from "primeng/splitbutton";
import { ItineraryConfigurationComponent } from "../itinerary-configuration/itinerary-configuration.component";
import { ItineraryMultilanguageContentComponent } from "../itinerary-multilanguage-content/itinerary-multilanguage-content.component";
import { PointOfInterestListComponent } from "../point-of-interest-list/point-of-interest-list.component";
import { ConfirmationPopupComponent } from "../../../Popups/confirmation-popup/confirmation-popup.component";
import { PublicationPopupComponent } from "../../../Popups/publication-popup/publication-popup.component";
import { DatePipe } from "@angular/common";
import { CompletionStateComponent } from "app/ReusableComponents/completion-state/completion-state.component";

@Component({
  selector: "app-itinerary-management-page",
  templateUrl: "./itinerary-management-page.component.html",
  styleUrls: ["./itinerary-management-page.component.css"],
  imports: [
    PublicationStateComponent,
    ProgressSpinner,
    RouterLink,
    SplitButton,
    ItineraryConfigurationComponent,
    ItineraryMultilanguageContentComponent,
    PointOfInterestListComponent,
    ConfirmationPopupComponent,
    PublicationPopupComponent,
    DatePipe,
    CompletionStateComponent
  ],
})
export class ItineraryManagementPageComponent implements OnInit, OnDestroy {
  isCreation: boolean = true;

  itinerary?: ItineraryDetail;

  itineraryTranslation: ItineraryTranslation[] = [];
  selectedLanguageId!: number;

  languagesSelected: Language[] = [];

  lastPublication!: ItineraryPublication;

  // Forms
  informationsForm: FormGroup;
  multiLanguageForm: FormGroup;

  //Enumerations
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;
  enumPublishState = publishState;

  // Dialog attributes
  visible: boolean = false;
  visiblePublication: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;
  idSelected!: number;

  //splitButton attributes
  menuItems: MenuItem[] = [];

  // Mock for languages List
  //TODO: assign values from the backend
  languagesList: Language[] = [];

  private destroySubject = new Subject();

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.informationsForm = this.formBuilder.group({
      itineraryLabel: [this.itinerary?.identificationName, Validators.required],
      languages: [""],
      isLocated: [false],
    });

    this.multiLanguageForm = this.formBuilder.group({
      itineraryName: [""],
      currentLanguage: [""],
      details: [""],
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;

    // Retrieve an existing itinerary
    if (routeParams.get("id") != null) {
      const itineraryIdFromRoute = Number(routeParams.get("id"));

      this.dataService
        .getItineraryState(itineraryIdFromRoute)
        .pipe(takeUntil(this.destroySubject))
        .subscribe((response) => {
          if (this.itinerary?.state === this.enumPublishState.PublicationInProgress && response === this.enumPublishState.Published) {
            this.itinerary.state = response;
            this.itinerary.pointOfInterests.forEach((pointOfInterest) => {
              pointOfInterest.state = response;
            });
          }
        });

      this.isCreation = false;

      forkJoin([this.dataService.getItinerary(itineraryIdFromRoute), this.dataService.getLanguages()]).subscribe(([itinerary, languages]) => {
        this.languagesList = languages;
        this.itinerary = itinerary;

        this.informationsForm.get(["itineraryLabel"])?.setValue(itinerary.identificationName);
        this.informationsForm.get(["isLocated"])?.setValue(itinerary.isLocated);

        if (this.itinerary?.itineraryTranslations.length > 0) {
          this.itineraryTranslation = this.itinerary.itineraryTranslations;

          this.multiLanguageForm.get(["itineraryName"])?.setValue(this.itineraryTranslation[0].name);
          this.multiLanguageForm.get(["details"])?.setValue(this.itineraryTranslation[0].details);
          this.multiLanguageForm.get(["currentLanguage"])?.setValue(this.itineraryTranslation[0].languageId);
          this.selectedLanguageId = this.itineraryTranslation[0].languageId;
        }

        this.languagesSelected = this.setLanguagesSelected();

        this.setPageTitle();
      });

      this.menuItems = [
        {
          label: "Publier",
          title: "Publier le parcours",
          command: () => {
            this.publishItinerary();
          },
        },
        {
          label: "Supprimer",
          title: "Supprimer le parcours",
          command: () => {
            this.showDialog(this.enumDialogType.Delete, this.enumEntityType.Itinerary, undefined);
          },
        },
      ];
    }
    // Create a new itinerary
    else {
      this.dataService.getLanguages().subscribe((languages) => {
        this.languagesList = languages;
      });
    }
    this.setPageTitle();
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
    this.destroySubject.complete();
  }

  createItinerary() {
    if (this.isInformationsFormValid()) {
      this.saveLanguage();

      const newItinerary: ItineraryDetail = {
        id: 0,
        identificationName: this.informationsForm.get(["itineraryLabel"])?.value,
        // details: this.informationsForm.get(['details'])?.value,
        state: publishState.NotPublished,
        readyForPublication: false,
        isLocated: this.informationsForm.get(["isLocated"])?.value,
        pointOfInterests: this.itinerary?.pointOfInterests || [],
        itineraryTranslations: this.itineraryTranslation,
      };

      this.dataService.createItinerary(newItinerary).subscribe({
        next: (itinerary) => {
          const projectName = this.authService.getProjectName();
          this.messageService.add({ severity: "success", summary: "Création réussie", detail: "Le parcours a bien été créé" });
          this.router.navigateByUrl(`${projectName}/itinerary-management/${itinerary.id}`);
        },
        error: (error) => {
          this.messageService.add({ severity: "error", summary: "Création non complétée", detail: "Le parcours n'a pas pu être créé" });
          console.log(error);
        },
      });
    }
  }

  updateItinerary() {
    if (this.isInformationsFormValid() && this.itinerary) {
      this.itinerary.identificationName = this.informationsForm.get(["itineraryLabel"])?.value;

      this.saveLanguage();

      this.itinerary.itineraryTranslations = this.itineraryTranslation;

      const newPointOfInterestList = this.itinerary.pointOfInterests;

      const newItinerary: ItineraryDetail = {
        id: this.itinerary.id,
        identificationName: this.informationsForm.get(["itineraryLabel"])?.value,
        // TODO: check why this was there. I don't know if we the details was needed there
        // or if it was an old thing
        //details: this.informationsForm.get(['details'])?.value,
        state: this.itinerary.state,
        readyForPublication: this.itinerary.readyForPublication,
        isLocated: this.informationsForm.get(["isLocated"])?.value,
        pointOfInterests: newPointOfInterestList,
        itineraryTranslations: this.itineraryTranslation,
        lastModificationDate: this.itinerary.lastModificationDate,
        publicationDate: this.itinerary.publicationDate,
        itineraryImage: this.itinerary.itineraryImage,
      };

      this.dataService.updateItinerary(newItinerary).subscribe({
        next: (data) => {
          switch (data.errorCode) {
            case responseCode.SuccessfulUpdate:
              this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "Le parcours a bien été modifié" });
              break;
            case responseCode.NotModified:
              this.messageService.add({ severity: "warn", summary: "Aucune modification", detail: "Aucune modification n'a été effectuée" });
              break;
            case responseCode.ImpossibleUpdate:
              this.messageService.add({ severity: "error", summary: "Modification impossible", detail: "Aucune modification ne peut être effectuée lors d'une publication" });
              break;
          }
          this.itinerary = data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  displayItineraryState() {
    if (this.itinerary) {
      return getPublishStateValue(this.itinerary.state);
    } else {
      return getPublishStateValue(this.enumPublishState.NotPublished);
    }
  }

  deleteItinerary() {
    this.visible = false;

    if (this.itinerary && this.itinerary.state !== this.enumPublishState.PublicationInProgress) {
      this.dataService.deleteItinerary(this.itinerary.id).subscribe({
        next: (result) => {
          switch (result.errorCode) {
            case responseCode.SuccessfulDeletion:
              this.messageService.add({ severity: "success", summary: "Suppression réussie", detail: "Le parcours a bien été supprimé" });
              this.router.navigateByUrl(`${this.authService.getProjectName()}/area-management`);
              break;
            case responseCode.ImpossibleDeletion:
              this.messageService.add({ severity: "error", summary: "Suppression Impossible", detail: result.errorMessage });
              break;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.messageService.add({ severity: "error", summary: "Suppression impossible", detail: "Il n'est pas possible de supprimer un parcours qui est en cours de publication" });
    }
  }

  isInformationsFormValid(): boolean {
    if (this.informationsForm.valid) {
      return true;
    }

    if (!this.informationsForm.get(["itineraryLabel"])?.valid) {
      this.messageService.add({ severity: "warn", summary: "Champ invalide", detail: "Le label du parcours est invalide" });
    }

    return false;
  }

  showDialog(confirmTypeAction: number, entityType: number, id?: number) {
    this.confirmTypeAction = confirmTypeAction;
    this.entityTypeAction = entityType;
    if (id) this.idSelected = id;
    this.visible = true;
  }

  confirmActionDialog(confirmAction: boolean) {
    this.visible = false;
    if (confirmAction == true) {
      if (this.entityTypeAction == this.enumEntityType.Itinerary) {
        this.deleteItinerary();
      } else if (this.entityTypeAction == this.enumEntityType.PointOfInterest) {
        this.deletePointOfInterest(this.idSelected);
      }
    }
  }

  deletePointOfInterest(pointOfInterestId: number) {
    this.itinerary?.pointOfInterests.splice(
      this.itinerary?.pointOfInterests.findIndex((poi) => poi.id == pointOfInterestId),
      1,
    );
    this.affectOrderNumberToPointsOfInterest();
  }

  affectOrderNumberToPointsOfInterest() {
    this.itinerary?.pointOfInterests.forEach((pointOfInterest, index) => {
      pointOfInterest.order = index + 1;
    });
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];

    this.languagesList.forEach((language) => {
      if (this.itineraryTranslation.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });

    return languageArray;
  }

  addItineraryTranslation(languageIdFromEvent: number): ItineraryTranslation {
    return {
      languageId: languageIdFromEvent,
      name: "",
      details: "",
    };
  }

  configLanguageChanged(languageIdFromEvent: number) {
    const indexFirstLanguageArray = this.itineraryTranslation.findIndex((translation) => translation.languageId == languageIdFromEvent);
    if (indexFirstLanguageArray == -1) {
      //If translation doesn't exist in itinerary, add it
      const newItineraryTranslation: ItineraryTranslation = this.addItineraryTranslation(languageIdFromEvent);

      this.itineraryTranslation.push(newItineraryTranslation);
      if (this.itineraryTranslation.length == 1) {
        this.selectedLanguageId = languageIdFromEvent;
        this.multiLanguageForm.get(["currentLanguage"])?.setValue(this.selectedLanguageId);
      }
    } else {
      this.itineraryTranslation.splice(Number(indexFirstLanguageArray), 1);
      if (this.itineraryTranslation.length > 0) {
        this.multiLanguageForm.get(["itineraryName"])?.setValue(this.itineraryTranslation[0].name);
        this.multiLanguageForm.get(["details"])?.setValue(this.itineraryTranslation[0].details);
        this.multiLanguageForm.get(["currentLanguage"])?.setValue(this.itineraryTranslation[0].languageId);
        this.selectedLanguageId = this.itineraryTranslation[0].languageId;
      } else {
        this.multiLanguageForm.get(["itineraryName"])?.setValue("");
        this.multiLanguageForm.get(["details"])?.setValue("");
      }
    }

    this.languagesSelected = this.setLanguagesSelected();
  }

  changeItineraryImage(media: Media) {
    if (this.itinerary) {
      this.itinerary.itineraryImage = media;
    }
  }

  saveLanguage() {
    const indexLanguageModifications = this.itineraryTranslation.findIndex((translation) => translation.languageId == this.selectedLanguageId);

    if (indexLanguageModifications != -1) {
      const newLanguageInfo: ItineraryTranslation = {
        languageId: this.selectedLanguageId,
        name: this.multiLanguageForm.get(["itineraryName"])?.value,
        details: this.multiLanguageForm.get(["details"])?.value,
      };

      this.itineraryTranslation[indexLanguageModifications] = newLanguageInfo;
    }
  }

  languageChanged(currentLanguageId: number) {
    this.saveLanguage();

    const indexCurrentLanguage = this.itineraryTranslation.findIndex((translation) => translation.languageId == currentLanguageId);

    this.multiLanguageForm.get(["itineraryName"])?.setValue(this.itineraryTranslation[indexCurrentLanguage].name);
    this.multiLanguageForm.get(["details"])?.setValue(this.itineraryTranslation[indexCurrentLanguage].details);

    this.selectedLanguageId = currentLanguageId;
  }

  navigateNewPointOfInterest() {
    if (this.itinerary) {
      const languageIds: number[] = [];

      this.itinerary.itineraryTranslations.forEach((translation) => {
        languageIds.push(translation.languageId);
      });

      const projectName = this.authService.getProjectName();

      this.router.navigateByUrl(`${projectName}/point-of-interest-management/new`, {
        state: {
          itineraryId: this.itinerary.id,
          languageIds: languageIds,
          isLocated: this.itinerary.isLocated,
        },
      });
    } else {
      //TODO: display message: create itinerary before create a POI.
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Action requise", detail: "Veuillez créer le parcours avant de créer une étape" });
    }
  }

  publishItinerary() {
    if (this.itinerary && this.itinerary.state !== this.enumPublishState.PublicationInProgress) {
      this.dataService.publishItinerary(this.itinerary.id).subscribe((response) => {
        this.lastPublication = response.data;

        if (this.itinerary && response.errorCode === responseCode.PublicationStarted) {
          this.itinerary.state = this.enumPublishState.PublicationInProgress;
          this.itinerary.pointOfInterests.forEach((pointOfInterest) => {
            pointOfInterest.state = this.enumPublishState.PublicationInProgress;
          });
        } else if (response.errorCode === responseCode.ImpossiblePublication) {
          this.visiblePublication = true;
        }
      });
    } else if (this.itinerary?.state === this.enumPublishState.PublicationInProgress) {
      this.messageService.add({ severity: "warn", summary: "Publication en cours", detail: "Veuillez attendre que la publication en cours soit terminée" });
    }
  }

  closePublicationPopup() {
    this.visiblePublication = false;
  }

  changePOICoordinates(event: PointOfInterest) {
    const currentPOI = this.itinerary?.pointOfInterests.find((point) => point.id === event.id);
    if (currentPOI) {
      currentPOI.latitude = event.latitude;
      currentPOI.longitude = event.longitude;
    }
  }

  storeEntity() {
    localStorage.setItem("entityType", "itinerary");
    if (this.itinerary) {
      localStorage.setItem("entityId", this.itinerary?.id.toString());
    }
  }

  setPageTitle() {
    if (this.isCreation) {
      document.title = "Parcours - Création";
    } else if (this.itinerary) {
      document.title = "Parcours - " + this.itinerary.identificationName;
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
