<div class="card" [ngClass]="{ 'component-disabled': isCreation }" [style.height.px]="getHeightCard()">
  @if (dragged) {
    <div (window:mouseup)="stop($event)" (window:mousemove)="move($event)"></div>
  }

  <div (window:scrollend)="onScrollEnd()"></div>

  <div class="header">
    <div class="horizontal-display">
      <img class="icon" src="assets/icons/Etape_2X.png" alt="icon"/>
      <h2>Étapes</h2>
    </div>

    <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une étape" (click)="navigate()" (keypress)="navigate()"> </i>
  </div>

  @if (!pointOfInterestList || pointOfInterestList.length === 0) {
    <div>
      <p>Aucune étape n'est associé au parcours. Veuillez en ajouter une en cliquant sur l'icône plus en haut à droite de la section.</p>
    </div>
  }

  <div class="item-container" #itemContainer [style.height.px]="getHeightCard() - 200">
    @for (pointOfInterest of pointOfInterestList; track pointOfInterest; let indexOfelement = $index) {
      <app-point-of-interest-card
        #dragItem
        class="item"
        [ngClass]="indexOfelement === indexDraggedItem ? 'bring-to-front' : 'set-to-back'"
        [style.top.px]="indexOfelement * distanceBetweenCards"
        [pointOfInterest]="pointOfInterest"
        (dragStartedEmitter)="start($event)"
        (deletePointOfInterestEmitter)="deletePointOfInterest($event)">
      </app-point-of-interest-card>
    }
  </div>
</div>
