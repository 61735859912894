<div class="body">
  <div class="page">
    <h1><i class="bi bi-gear" title="Administration"></i> Administration</h1>
    @if (administrationType !== enumAdministrationType.Project) {
      <div class="horizontal-display">
        <a routerLink="/{{ this.getProjectName() }}/administration/projects" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
          <p
            class="tab-section"
            aria-hidden="true"
            [class.selected]="administrationType === enumAdministrationType.Projects">
            Projets
          </p>
        </a>

        <a routerLink="/{{ this.getProjectName() }}/administration/users" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
        <p
          class="tab-section"
          aria-hidden="true"
          [class.selected]="administrationType === enumAdministrationType.Users">
          Utilisateurs
        </p>
        </a>

        <a routerLink="/{{ this.getProjectName() }}/administration/custom-modules" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
          <p
            class="tab-section"
            aria-hidden="true"
            [class.selected]="administrationType === enumAdministrationType.ModuleCustom">
            Modules spéciaux
          </p>
        </a>

        <a routerLink="/{{ this.getProjectName() }}/administration/languages" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
          <p
            class="tab-section"
            aria-hidden="true"
            [class.selected]="administrationType === enumAdministrationType.Languages">
            Langues
          </p>
        </a>

        <a routerLink="/{{ this.getProjectName() }}/administration/debug" title="Choisir un projet" class="navbar-nav-item-link" routerLinkActive="active">
          <p
            class="tab-section"
            aria-hidden="true"
            [class.selected]="administrationType === enumAdministrationType.Debug">
            Debug
          </p>
        </a>
      </div>
    }
    <div class="content">
      @switch (administrationType) {
        @case (enumAdministrationType.Projects) {
          @if (modeCreation) {
            <div class="form-container">
              <h3>Nouveau projet</h3>
              <div>
                <span>Nom du projet:</span>
                <input pInputText id="projectName" [formControl]="projectName" />
              </div>
              <div>
                <span>Plateformes:</span>
                <p-multiSelect
                  [showHeader]="false"
                  [options]="buildPlatforms"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Choisir une/des plateformes de build"
                  emptyMessage="Aucune plateforme de build n'a été trouvée"
                  [formControl]="projectBuildPlatforms">
                </p-multiSelect>
              </div>
              <button class="button margin-left" (click)="createProject()">Créer</button>
            </div>
          } @else {
            <button class="button" (click)="modeCreation = true">Créer un projet</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th class="long-columns-projects-table">Nom</th>
              <th class="long-columns-projects-table">Plateformes de build</th>
              <th class="align-center">Actions</th>
            </tr>
            @for (project of projects; let indexProject = $index; track indexProject) {
              <tr>
                <td class="table-project-item">{{ project.id }}</td>
                <td class="table-project-item">{{ project.identificationName }}</td>
                <td>
                  @if (project.buildPlatforms.length > 0) {
                    @for (buildPlatform of project.buildPlatforms; let indexBuildPlatform = $index; track indexBuildPlatform) {
                      <div class="horizontal-display">{{ buildPlatform.name }}</div>
                    }
                  } @else {
                    Aucune plateforme n'est associée au projet.
                  }
                </td>
                <td class="align-center">
                  <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Modifier" (click)="navigateProject(project.id)" (keypress)="navigateProject(project.id)"> </i>
                </td>
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.Users) {
          @if (modeCreation) {
            <form [formGroup]="userForm">
              <div class="form-container">
                <h3>Nouvel utilisateur</h3>

                <div class="form-field">
                  <label htmlFor="emailAddress" class="margin-right">Adresse email</label>
                  <input pInputText id="emailAddress" formControlName="emailAddress" style="width: 80%" />
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="surname" class="margin-right">Nom</label>
                  <input pInputText id="surname" formControlName="surname" style="width: 80%" />
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="firstName" class="margin-right">Prénom</label>
                  <input pInputText id="firstName" formControlName="firstName" style="width: 80%" />
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="role">Rôle de l'utilisateur</label>
                  <p-select id="role" [options]="rolesList" formControlName="role" placeholder="Choisir un rôle" />
                </div>
                <div class="bottom-line"></div>

                <div class="form-field">
                  <label htmlFor="projectAssociated">Projet associé</label>
                  <!-- <p-dropdown id="projectAssociated" [options]="projects" optionId="id" optionLabel="identificationName" formControlName="projectAssociated" placeholder="Choisir un projet">
                  </p-dropdown> -->
                  <p-multiSelect
                    id="projectAssociated"
                    [options]="projects"
                    optionValue="id"
                    optionLabel="identificationName"
                    formControlName="projectAssociated"
                    placeholder="Choisir un projet"
                    emptyMessage="Aucun projet n'a été trouvé"
                    [showHeader]="true">
                  </p-multiSelect>
                </div>
                <div class="horizontal-display">
                  <button class="button" (click)="createUser()">Ajouter</button>
                  @if (requestInProgress) {
                    <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
                  }
                </div>
              </div>
            </form>
          } @else {
            <button class="button" (click)="modeCreation = true">Ajouter un utilisateur</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th>Adresse e-mail</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Role</th>
              <th>Project</th>
              <th>Email confirmé</th>
              <th>Mot de passe ajouté</th>
              <th>Actions</th>
            </tr>
            @for (user of users; track $index) {
              <tr class="user-table">
                <td class="table-user-item">{{ user.id }}</td>
                <td class="table-user-item">{{ user.email }}</td>
                @if (indexUserToModify === $index) {
                  <td>
                    <input pInputText id="surname" [formControl]="userSurname" />
                  </td>
                  <td>
                    <input pInputText id="firstName" [formControl]="userFirstName" />
                  </td>
                  <td>
                    <p-select [options]="rolesList" [formControl]="userRole" />
                  </td>
                  <td>
                    <p-multiSelect
                      [options]="projects"
                      optionLabel="identificationName"
                      optionValue="id"
                      [formControl]="userProjects"
                      [showHeader]="false"
                      placeholder="Choisir un projet"
                      emptyMessage="Aucun projet n'a été trouvé">
                    </p-multiSelect>
                  </td>
                } @else {
                  <td class="table-user-item">{{ user.surname }}</td>
                  <td class="table-user-item">{{ user.firstName }}</td>
                  <td class="table-user-item">{{ user.role }}</td>
                  <td class="table-user-item">
                    @for (project of user.projects; let indexProject = $index; track indexProject) {
                      <p class="project-name-user">{{ user.projects[indexProject].identificationName }}</p>
                    }
                  </td>
                }
                <td class="table-user-item">
                  <span class="table-chip" [ngClass]="user.emailConfirmed ? 'green-state' : 'red-state'">{{ displayBoolean(user.emailConfirmed) }}</span>
                  @if (!user.emailConfirmed) {
                    <a aria-hidden="true" class="send-email-link" (click)="sendConfirmAndSetPasswordEmail($index, user.email)" (keypress)="sendConfirmAndSetPasswordEmail($index, user.email)">Renvoyer l'invitation</a>
                    @if (sendConfirmationEmailClient === $index) {
                      <p-progressSpinner class="spinner"></p-progressSpinner>
                    }
                  }
                </td>
                <td class="table-user-item">
                  <span class="table-chip" [ngClass]="user.passwordSet ? 'green-state' : 'red-state'">{{ displayBoolean(user.passwordSet!) }}</span>
                </td>
                @if (user.email === "admin@ohrizon.com") {
                  <td title="Aucune action disponible"></td>
                } @else {
                  @if (indexUserToModify === $index) {
                    <td class="align-center">
                      <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="updateUser()" (keypress)="updateUser()"> </i>
                      <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Abandonner les changements" (click)="resetUserModification()" (keypress)="resetUserModification()">
                      </i>
                    </td>
                  } @else {
                    <td>
                      <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="modifyUser($index)" (keypress)="modifyUser($index)"> </i>
                      <i tabIndex="0" aria-hidden="true" class="bi bi-trash btn-action-file" title="Supprimer" (click)="deleteUser($index)" (keypress)="deleteUser($index)"> </i>
                    </td>
                  }
                }
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.ModuleCustom) {
          <app-custom-modules
            [projects]="projects"
            [moduleCustomList]="moduleCustomList">
          </app-custom-modules>
        }
        @case (enumAdministrationType.Languages) {
          @if (languages.length > 0) {
            <table>
              <tr>
                <th>Id</th>
                <th>Langue</th>
                <th>Code</th>
              </tr>
              @for (language of languages; track $index) {
                <tr>
                  <td class="table-user-item">{{ language.id }}</td>
                  <td class="table-user-item">{{ language.name }}</td>
                  <td class="table-user-item">{{ language.code }}</td>
                </tr>
              }
            </table>
          } @else {
            <p>Aucune langue n'est associée.</p>
          }
        }
        @case (enumAdministrationType.Debug) {
          <div class="horizontal-display">
            <button [disabled]="requestInProgress" class="button margin-left" (click)="generateMissingThumbnails(true)">Générer les miniatures manquantes</button>
            <button [disabled]="requestInProgress" class="button margin-left" (click)="generateMissingThumbnails(false)">Regénérer toutes les miniatures</button>
            @if (requestInProgress) {
              <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
            }
          </div>
          <div>
            @if (mediasUpdated.length > 0)
            {
              Les miniatures ont été générées pour les médias suivants :
              @for (media of mediasUpdated; track $index) {
                <p>Id : {{media.id}} - Nom : {{media.name}}</p>
              }
            }
            @if (noMediaUpdated)
            {
                Aucune miniature n'a été générée, les miniatures étaient déjà présentes pour tous les médias.
            }
          </div>
        }
        @case (enumAdministrationType.Project) {
          <app-project></app-project>
        }
      }
    </div>
  </div>
</div>

<app-confirmation-popup
  [visible]="visibleConfirmActionPopup"
  [confirmTypeAction]="confirmTypeAction"
  [entityTypeAction]="7"
  [confirmationLabel]="userEmail"
  (confirmActionEmitter)="confirmDeleteUser($event)">
</app-confirmation-popup>
