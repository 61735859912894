import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Language } from 'app/Models/language';
import { AnswerParticipatory, Category, Module } from 'app/Models/module';
import { InformationPopupComponent } from 'app/Popups/previsualisation/information-popup/information-popup.component';
import { DataService } from 'app/Services/Data-Service/data.service';
import { ProgressSpinner } from 'primeng/progressspinner';

@Component({
  selector: 'app-quiz-part-previsualisation',
  templateUrl: './quiz-part-previsualisation.component.html',
  styleUrl: './quiz-part-previsualisation.component.css',
  imports: [NgClass, InformationPopupComponent, ProgressSpinner]
})
export class QuizPartPrevisualisationComponent implements OnChanges {

  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  module?: Module;
  languagesSelected?: Language[] = [];
  isInformationPopupVisible: boolean = false;
  synthesisVisualisation: boolean = false;

  currentCategory?: Category;
  currentCategoryIndex: number = 0;
  selectedAnswerList: boolean[][] = [];
  categoryScores: number[] = [];
  maxAnswers: number[] = [];

  synthesisFileUrl: string = '';

  constructor(
    private dataService: DataService,
  ) {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getModule(this.entityId.toString()).subscribe((module) => {
        this.module = module;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
        this.isInformationPopupVisible = true;
        this.maxAnswers = Array(8).fill(0);
        for (let i = 0; i < module.questions[0].categories!.length; i++) {
          this.categoryScores[i] = 0;
        }
        if (module.questions[0].categories) {
          this.currentCategory = module.questions[0].categories[0];
          for (let j = 0; j < module.questions[0].categories.length; j++) {
            this.selectedAnswerList[j] = [];
            for (let i = 0; i < module.questions[0].categories[j].answers.length; i++) {
              this.selectedAnswerList[j][i] = false;
            }
          }
        }
        this.setupImages();
        if (this.module?.mediaSynthesis) {
          this.dataService.getFile(this.module!.mediaSynthesis).subscribe({
            next: (file) => {
              this.synthesisFileUrl = URL.createObjectURL(file);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
        }
        setTimeout(() => {
          const div1 = document.querySelector<HTMLElement>(".fake-scrollbar");
          const div2 = document.querySelector<HTMLElement>(".answer-list");

          if (div1 && div2) {
            let isSyncing = false;

            function syncScroll(source: HTMLElement, target: HTMLElement) {
              if (isSyncing) return;
              isSyncing = true;
              target.scrollTop = source.scrollTop;
              setTimeout(() => (isSyncing = false), 10);
            }

            div1.addEventListener("scroll", () => syncScroll(div1, div2));
            div2.addEventListener("scroll", () => syncScroll(div2, div1));
          }
        }, 100);
      })
    }
  }

  async setupImages() {
    this.currentCategoryIndex = 0;

    await this.loadSvg(".category-top", "assets/images/Categorie_Top.svg", "0 0 320 320");
    await this.loadSvg(".category-middle", "assets/images/Categorie_Middle.svg", "0 0 300 300");
    await this.loadSvg(".category-bottom", "assets/images/Categorie_Bottom.svg", "0 0 320 320");

    this.currentCategoryIndex = 0;
  }

  async loadSvg(selector: string, file: string, viewBox: string) {
    const response = await fetch(file);
    const data = await response.text();

    document.querySelectorAll(selector).forEach(div => {
      div.innerHTML = data;
    });

    document.querySelectorAll(`${selector} svg path`).forEach(div => {
      if (this.module?.questions[0].categories && this.module?.questions[0].categories[this.currentCategoryIndex]?.color) {
        div.setAttribute("fill", this.module.questions[0].categories[this.currentCategoryIndex].color);
      }
      this.currentCategoryIndex++;
    });

    document.querySelectorAll(`${selector} svg`).forEach(div => {
      div.setAttribute("viewBox", viewBox);
    });
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    this.languagesSelected = languageArray;
    return languageArray;
  }

  selectCategory(index: number) {
    this.currentCategoryIndex = index;
    if (this.module?.questions[0].categories) {
      this.currentCategory = this.module.questions[0].categories[index];
    }
  }

  getColor(): string {
    if (this.currentCategory?.color) {
      return 'border: 3px solid ' + this.currentCategory?.color + '; background-color: ' + this.currentCategory?.color + ';';
    } else {
      return  'border: 3px solid #363a3c; background-color: #363a3c;';
    }
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayQuestion(): string | undefined {
    const question = this.module?.questions[0];
    const selectedTranslation = question?.questionTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.question;
    }
    return undefined;
  }

  displayCategory(): string | undefined {
    const selectedTranslation = this.currentCategory?.categoryTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.category + ' (' + this.getSelectedAnswerCount().toString() + '/' + this.currentCategory?.answers.length.toString() + ')';
    }
    return undefined;
  }

  getSelectedAnswerCount(): number {
    let count = 0;
    for (let i = 0; i < this.selectedAnswerList[this.currentCategoryIndex].length; i++) {
      if (this.selectedAnswerList[this.currentCategoryIndex][i]) {
        count++;
      }
    }
    return count;
  }

  displayAnswer(answer: AnswerParticipatory): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  selectAnswer(index: number) {
    if (!this.selectedAnswerList[this.currentCategoryIndex][index]) {
      this.incrementCategory(true);
    }
    this.selectedAnswerList[this.currentCategoryIndex][index] = true;
  }

  incrementCategory(type: boolean) {
    if (type) {
      if (this.categoryScores[this.currentCategoryIndex] < 4) {
        this.categoryScores[this.currentCategoryIndex]++;
      }
    } else {
      if (this.categoryScores[this.currentCategoryIndex] > 0) {
        this.categoryScores[this.currentCategoryIndex]--;
      }
    }
  }

  getIconSize(index: number): number {
    switch (this.categoryScores[index]) {
      case 0:
        if (this.module?.growingIcon) {
          return 0;
        } else {
          return 44;
        }
      case 1:
        if (this.module?.growingIcon) {
          return 11;
        } else {
          return 33;
        }
      case 2:
        return 22;
      case 3:
        if (this.module?.growingIcon) {
          return 33;
        } else {
          return 11;
        }
      case 4:
        if (this.module?.growingIcon) {
          return 44;
        } else {
          return 0;
        }

      default:
        return 44;
    }
  }

  displayTimer(): string {
    let minutes = 0;
    let secs = 0;
    if (this.module?.timer) {
      minutes = Math.floor(this.module?.timer / 60);
      secs = this.module?.timer % 60;
    }
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  }

  displaySynthesis(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.synthesis;
    }
    return undefined;
  }

  toggleSynthesisVisualisation() {
    if (this.synthesisVisualisation) {
      this.synthesisVisualisation = false;
      this.setupImages();
    } else {
      this.synthesisVisualisation = true;
      if (this.module?.questions[0].categories) {
        this.currentCategory = this.module.questions[0].categories[0];
        this.currentCategoryIndex = 0;
      }
    }
  }

  backArrowClicked() {
    if (this.module?.questions[0].categories) {
      this.currentCategory = this.module.questions[0].categories[0];
      this.currentCategoryIndex = 0;
    }
    if (this.module?.pointOfInterestId) {
      this.entityIdChanged.emit(this.module?.pointOfInterestId);
    }
    this.entityTypeChanged.emit('pointOfInterest');
  }

}
